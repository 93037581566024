import {
  ButtonComponent,
  DropdownOption,
  generateNotification,
  NotificationTypes,
  TableComponent,
  TableRow,
  Tag,
} from "deinestadtliebt-component-library";
import {
  ActivityEditCompanion,
  createEmptyActivityEditCompanion,
} from "../../components/newEventEdit/EventEditBoxComponent.types";
import {
  copyAction,
  createAction,
  deleteAction,
  getProviderNameFroIdNameMap,
  updateAction,
} from "../action/ActionUtils";
import { v4 as uuidv4 } from "uuid";
import {
  createEmptyTempTimelapse,
  Event,
  TempTimelapse,
  TicketType,
} from "../event/Event.types";
import {
  copyEvent,
  createEvent,
  deleteEvent,
  transformCategoryToPriceItems,
  updateEventOnBackend,
  updateFlyerIdFromCompanion,
} from "../event/EventUtils";
import { generateTimeStringForDate } from "../time/TimeUtils";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as OpenIcon } from "../../assets/icons/open.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { Category, IdName } from "../user/User.types";
import i18n from "../../i18n";
import { Action, DiscountType } from "../action/Action.types";
import {
  ActivityTag,
  ActivityType,
  EventHashTable,
  createEmptyDateItem,
  DateItem,
  DateState,
  DetailFilter,
  ImageData,
  TargetAudience,
  ActionHashTable,
  PopupType,
} from "./Activity.types";
import { AxiosInstance } from "axios";
import { FileType } from "../appConfig/config.types";
import { ButtonComponentProps } from "deinestadtliebt-component-library/build/ButtonComponent/ButtonComponent.types";

export enum RequiredCompanionProps {
  LOCATION = "LOCATION",
  CITY = "CITY",
  ZIPCODE = "ZIPCODE",
  STREET = "STREET",
  NAME = "NAME",
  DESCRIPTION = "DESCRIPTION",
  DISCOUNTDESC = "DISCOUNTDESC",
}

/**
 * Method to determine whether certain given properties are correctly set for given companion-object
 * @param companion companion containing all data
 * @param property to be checked
 * @returns true when property is set correctly
 */
export const checkCertainPropOfCompanion = (
  companion: ActivityEditCompanion,
  property: RequiredCompanionProps
) => {
  //TODO: expand for other properties
  switch (property) {
    case RequiredCompanionProps.CITY:
      return companion.event.location.city.length > 0;
    case RequiredCompanionProps.STREET:
      return companion.event.location.street.length > 0;
    case RequiredCompanionProps.ZIPCODE:
      return companion.event.location.zipCode.length === 5;
    case RequiredCompanionProps.NAME:
      return companion.event.name.length > 0;
    case RequiredCompanionProps.DESCRIPTION:
      return companion.event.description.length > 0;
    case RequiredCompanionProps.DISCOUNTDESC:
      return !(
        companion.action.discountType === DiscountType.SPECIAL &&
        ((typeof companion.action.discountDetails === "string" &&
          (companion.action.discountDetails === "" ||
            companion.action.discountDetails.length < 1)) ||
          companion.action.discountDetails === undefined)
      );
  }
};

/**
 * Helper to generate row entries for the event overview table
 * @param events all loaded events
 * @param actions all loaded actions
 * @param onUpdate method that opens editor for activities
 * @param onDetail method that opens editor to move or change activity dates
 * @param onDelete delete method for an activity
 * @param showOld boolean to determine if already passed events should be displayed
 * @param isAdmin boolean to determine wether providerNames should be displayed or not
 * @returns generated table row array
 * @tested
 */
export const generateCombinedActivityTableRows = (
  events: Event[],
  actions: Action[],
  onDuplicate: Function,
  onUpdate: Function,
  onCombinedSelect: Function,
  onDetail: Function,
  onDelete: Function,
  showOld: boolean,
  isAdmin: boolean,
  mapIdAndName?: IdName[]
): TableRow[] => {
  let currentRows: TableRow[] = [];
  let combinedEventHashTable: EventHashTable = {};
  let combinedActionHashTable: ActionHashTable = {};

  events
    .filter((item) => {
      if (showOld) return true;
      return isActivityInTheFuture(item);
    })
    .forEach((event) => {
      const combinedIdentifier = `${event.name}-${event.providerId}`;
      if (!combinedEventHashTable[combinedIdentifier])
        combinedEventHashTable[combinedIdentifier] = [event];
      else if (
        event.providerId ===
        combinedEventHashTable[combinedIdentifier][0].providerId
      )
        combinedEventHashTable[combinedIdentifier].push(event);
      else combinedEventHashTable[combinedIdentifier] = [event];
    });
  actions
    .filter((item) => {
      if (showOld) return true;
      return isActivityInTheFuture(item);
    })
    .forEach((action) => {
      const combinedIdentifier = `${action.name}-${action.providerId}`;
      if (!combinedActionHashTable[combinedIdentifier])
        combinedActionHashTable[combinedIdentifier] = [action];
      else if (
        action.providerId ===
        combinedActionHashTable[combinedIdentifier][0].providerId
      )
        combinedActionHashTable[combinedIdentifier].push(action);
      else combinedActionHashTable[combinedIdentifier] = [action];
    });

  Object.keys(combinedEventHashTable).forEach((key) => {
    let isSingleEntry = combinedEventHashTable[key].length === 1;
    const event = combinedEventHashTable[key][0];
    let isEventVisible: boolean = false;
    if (new Date(event.showDate ? event.showDate : new Date()) >= new Date())
      isEventVisible = true;
    let currentProviderName: string = "";
    if (!!mapIdAndName) {
      currentProviderName = getProviderNameFroIdNameMap(
        mapIdAndName,
        event.providerId
      );
    }
    let convertedProviderName: string[] = !!currentProviderName
      ? [currentProviderName]
      : [];
    let createdRows: (JSX.Element | string)[] = [
      i18n.t("adminEventPage.typeEvent"),
      isSingleEntry
        ? createImprovedActivityDateString(event)
        : i18n.t("adminEventPage.multipleDates", {
            replace: { count: combinedEventHashTable[key].length },
          }),

      event.name,

      generateVisibilityString(isEventVisible, event.showDate!),
      <EditIcon
        onClick={() => {
          if (isSingleEntry) onUpdate(event);
          else
            onCombinedSelect(
              combinedEventHashTable[key],
              ActivityType.EVENT,
              PopupType.EDIT
            );
        }}
      />,
      <CopyIcon
        onClick={() => {
          if (isSingleEntry) onDuplicate(event, ActivityType.EVENT);
          else
            onCombinedSelect(
              combinedEventHashTable[key],
              ActivityType.EVENT,
              PopupType.DUPLICATE
            );
        }}
      />,
      <OpenIcon
        onClick={() => {
          if (isSingleEntry) onDetail(event);
          else
            onCombinedSelect(
              combinedEventHashTable[key],
              ActivityType.EVENT,
              PopupType.DETAIL
            );
        }}
      />,
      <TrashIcon
        onClick={() => {
          if (isSingleEntry) onDelete(event.id, ActivityType.EVENT);
          else
            onCombinedSelect(
              combinedEventHashTable[key],
              ActivityType.EVENT,
              PopupType.DELETE
            );
        }}
      />,
    ];

    if (isAdmin) createdRows.unshift(...convertedProviderName);
    currentRows.push({
      content: createdRows,
      id: event.id!,
    });
  });

  Object.keys(combinedActionHashTable).forEach((key) => {
    let isSingleEntry = combinedActionHashTable[key].length === 1;
    const action = combinedActionHashTable[key][0];
    let isActionVisible: boolean = false;
    if (new Date(action.showDate ? action.showDate : new Date()) >= new Date())
      isActionVisible = true;
    let currentProviderName: string = "";
    if (!!mapIdAndName) {
      currentProviderName = getProviderNameFroIdNameMap(
        mapIdAndName,
        action.providerId
      );
    }
    let convertedProviderName: string[] = !!currentProviderName
      ? [currentProviderName]
      : [];
    let createdRows: (JSX.Element | string)[] = [
      i18n.t("adminEventPage.typeAction"),
      isSingleEntry
        ? createImprovedActivityDateString(action)
        : i18n.t("adminEventPage.multipleDates", {
            replace: { count: combinedActionHashTable[key].length },
          }),

      action.name,

      generateVisibilityString(isActionVisible, action.showDate!),
      <EditIcon
        onClick={() => {
          if (isSingleEntry) onUpdate(undefined, action);
          else
            onCombinedSelect(
              combinedActionHashTable[key],
              ActivityType.ACTION,
              PopupType.EDIT
            );
        }}
      />,
      <CopyIcon
        onClick={() => {
          if (isSingleEntry) onDuplicate(action, ActivityType.ACTION);
          else
            onCombinedSelect(
              combinedActionHashTable[key],
              ActivityType.ACTION,
              PopupType.DUPLICATE
            );
        }}
      />,
      <OpenIcon
        onClick={() => {
          if (isSingleEntry) onDetail(action);
          else
            onCombinedSelect(
              combinedActionHashTable[key],
              ActivityType.ACTION,
              PopupType.DETAIL
            );
        }}
      />,
      <TrashIcon
        onClick={() => {
          if (isSingleEntry) onDelete(action.id, ActivityType.ACTION);
          else
            onCombinedSelect(
              combinedActionHashTable[key],
              ActivityType.ACTION,
              PopupType.DELETE
            );
        }}
      />,
    ];

    if (isAdmin) createdRows.unshift(...convertedProviderName);
    currentRows.push({
      content: createdRows,
      id: action.id!,
    });
  });
  return currentRows;
};

/**
 * Helper to generate the correct string vor Date-column
 * @param activity
 * @returns string containing information about date of event/action
 * @tested
 */
export const createImprovedActivityDateString = (
  activity: Event | Action
): string => {
  let generatedDateString = "";
  let generatedTimeString = "";
  //First switch for different date types
  switch (true) {
    case !!activity.startDate && !!activity.endDate: {
      generatedDateString = `${generateTimeStringForDate(
        activity.startDate
      )} - ${generateTimeStringForDate(activity.endDate)}`;
      break;
    }
    case !!activity.startDate: {
      generatedDateString = `${i18n.t(
        "eventUtil.from"
      )}${generateTimeStringForDate(activity.startDate)}`;
      break;
    }
    case !!activity.endDate: {
      generatedDateString = `${i18n.t(
        "eventUtil.until"
      )}${generateTimeStringForDate(activity.endDate)}`;
      break;
    }
    case !!activity.activityDates && activity.activityDates.length === 1: {
      generatedDateString = `${generateTimeStringForDate(
        activity.activityDates[0].date
      )}`;
      break;
    }
    case !!activity.activityDates && activity.activityDates.length > 1: {
      activity.activityDates.forEach((item) => {
        generatedDateString += `${generateTimeStringForDate(item.date)}, `;
      });
      generatedDateString = generatedDateString.substring(
        0,
        generatedDateString.length - 2
      );
      break;
    }
    case !!(activity as Event)?.eventDateList &&
      (activity as Event).eventDateList.length > 0: {
      generatedDateString = `${generateTimeStringForDate(
        (activity as Event).eventDateList[0]
      )}`;
      break;
    }
    case !!(activity as Action)?.actionDates &&
      (activity as Action).actionDates!.length > 0: {
      generatedDateString = `${generateTimeStringForDate(
        (activity as Action).actionDates![0]
      )}`;
      break;
    }
    default:
      generatedDateString = i18n.t("eventUtil.noEventDate");
  }
  //Second switch for TimeString
  switch (true) {
    case !!activity.startTime && !!activity.endTime: {
      generatedTimeString = `${activity.startTime} - ${activity.endTime}`;
      break;
    }
    case !!activity.startTime: {
      generatedTimeString = `${i18n.t("eventUtil.from")}${activity.startTime}`;
      break;
    }
    case !!activity.endTime: {
      generatedTimeString = `${i18n.t("eventUtil.until")}${activity.endTime}`;
      break;
    }
    default:
      generatedTimeString = "";
  }

  return `${generatedDateString} ${generatedTimeString}`;
};

/**
 * Helper to generate correct string for visibilityColumn for activities
 * @param isVisible boolean if activity is visible
 * @param visibilityDate showDate of activity
 * @returns string containing information about visibility of activity
 * @tested
 */
export const generateVisibilityString = (
  isVisible: boolean,
  visibilityDate: Date
): string => {
  if (isVisible)
    return `${i18n.t(
      "providerActionsConfigurationPage.showStart"
    )} ${generateTimeStringForDate(
      visibilityDate,
      i18n.t("eventUtil.noShowDate")
    )}`;
  else return i18n.t("providerActionsConfigurationPage.visible");
};

export /**
 * Helper to remove an event from the server and from the local list
 * @param activityId id to remove
 * @param type activityType
 * @param axios instance of axios
 * @param toggleLoading method to toggle Loading status
 * @param loadedEvents list of loaed events
 * @param loadedActions list of loaded actions
 * @param setLoadedEvents setter to set new list of loaded events after removal of event
 * @param setLoadedActions setter to set new list of loaded actions after removal of action
 */
const removeActivity = (
  activityId: string,
  type: ActivityType,
  axios: AxiosInstance,
  toggleLoading: (status: boolean) => {},
  loadedEvents: Event[],
  loadedActions: Action[],
  setLoadedEvents: (events: Event[]) => {},
  setLoadedActions: (events: Action[]) => {}
): void => {
  toggleLoading(true);
  switch (type) {
    case ActivityType.EVENT:
      deleteEvent(axios, activityId).then((success) => {
        if (success) {
          setLoadedEvents([
            ...loadedEvents.filter((item) => item.id !== activityId),
          ]);
          generateNotification(
            NotificationTypes.SUCCESS,
            i18n.t("notification.title.success.deleteSuccessful"),
            i18n.t("notification.content.success.eventDelete")
          );
        } else {
          generateNotification(
            NotificationTypes.ERROR,
            i18n.t("notification.title.error.error"),
            i18n.t("notification.content.error.eventDelete")
          );
        }
      });
      break;
    case ActivityType.ACTION:
      deleteAction(axios, activityId).then((success) => {
        if (success) {
          setLoadedActions([
            ...loadedActions.filter((item) => item.id !== activityId),
          ]);
          generateNotification(
            NotificationTypes.SUCCESS,
            i18n.t("notification.title.success.deleteSuccessful"),
            i18n.t("notification.content.success.actionDelete")
          );
        } else {
          generateNotification(
            NotificationTypes.ERROR,
            i18n.t("notification.title.error.error"),
            i18n.t("notification.content.error.actionDelete")
          );
        }
      });
      break;
    default:
  }
  toggleLoading(false);
};

/**
 * Method to convert TempTimelapse to DateItem
 * @param tempTl TempTimelapse to be converted
 * @returns converted DateItem
 * @tested
 */
export const transformTempTimeLapseToDateItem = (
  tempTl: TempTimelapse
): DateItem => {
  let localDateItem = createEmptyDateItem();
  localDateItem.date = tempTl?.eventDate || new Date();
  localDateItem.startTime = tempTl.startTime;
  localDateItem.endTime = tempTl.endTime;
  localDateItem.showDate = tempTl?.showDate || new Date();
  localDateItem.state = DateState.READY;

  return localDateItem;
};

/**
 * Method to generate an array of Events or ACtions from data in companion-object
 * @param companion companion containing all required information
 * @returns either an array of Actions or an array of Events, depending on companion-props, filled with data from companion
 */
export const generateActivityList = (
  companion: ActivityEditCompanion
): Event[] | Action[] => {
  let array: Event[] | Action[] = [];
  let event: Event = { ...companion.event };
  let action: Action = {
    ...companion.action,
  };

  switch (companion.activityType) {
    case ActivityType.EVENT:
      event.providerId = companion.providerId;
      event.priceItems = transformCategoryToPriceItems(
        companion.priceCategoryList
      );
      //Assigning step 1 data
      event.mainTag = companion.mainTag!;
      event.detailTags = companion.detailTags;
      event.customTags = companion.customTags;
      event.targetAudiences = companion.targetAudiences;
      event.category = companion.category!;
      //Assigning step 2 data not necessary for events, cause everything is already assigned
      event.image = companion.image;
      //Assigning step 4 data not necessary for events, cause everything is already assigned
      if (companion.event.ticketType.includes(TicketType.FREE)) {
        event.priceItems = [];
      }
      //Step 5
      if (companion.visibleFrom === "now") {
        event.showDate = new Date();
      } else {
        event.showDate = companion.singleDateObject.showDate;
      }

      //Assigning step 3 data and step 5 data in a loop to capture all necessary data
      event.startTime = companion.singleDateObject.startTime;
      event.endTime = companion.singleDateObject.endTime;
      event.startDate = undefined;
      event.endDate = undefined;
      event.activeDays = [];
      event.displayedDays = [];

      if (companion.dateType === "continuous") {
        event.startDate = companion.startDate;
        event.endDate = companion.endDate;
        event.link = event.dateLinkList[0];
        event.activeDays = companion.activeDays;
        event.activityDates = [
          transformTempTimeLapseToDateItem(companion.singleDateObject),
        ];
        array = [event];

        if (!!companion.displayedDays && companion?.displayedDays.length > 0)
          event.displayedDays = companion.displayedDays;
      } else if (companion.dateType === "single") {
        event.activityDates = [
          transformTempTimeLapseToDateItem(companion.singleDateObject),
        ];
        event.link = event.dateLinkList[0];
        array = [event];
      } else if (companion.connectedDates) {
        companion.dateList.forEach((date, index) => {
          event.activityDates[index] = transformTempTimeLapseToDateItem(date);
        });
        event.link = event.dateLinkList[0];
        array = [event];
      } else {
        let createdArray: Event[] = [];
        const seriesId: string = uuidv4();

        companion.dateList.forEach((date, dateIndex) => {
          let localEvent: Event = {
            ...event,
            activityDates: [transformTempTimeLapseToDateItem(date)],
            link: event.dateLinkList[dateIndex],
            id: dateIndex > 0 ? undefined : action?.id || event.id,
            slugId: dateIndex > 0 ? undefined : action?.slugId || event.slugId,
            seriesId,
          };
          if (companion.visibleFrom === "deadline") {
            localEvent.showDate =
              companion.dateList[dateIndex].showDate || new Date();
          } else if (companion.visibleFrom === "date") {
            localEvent.showDate = companion.singleDateObject.showDate;
          } else {
            localEvent.showDate = new Date();
          }
          createdArray.push(localEvent);
        });
        array = [...createdArray];
      }

      break;
    case ActivityType.ACTION:
      //Step 1
      action.providerId = companion.providerId;
      action.mainTag = companion.mainTag!;
      action.detailTags = companion.detailTags;
      action.customTags = companion.customTags;
      action.targetAudiences = companion.targetAudiences;
      action.categoryItem = companion.category!;
      if (!companion.actionLink) {
        action.actionLink = "";
        action.actionLinkText = "";
      }
      //Step 2
      action.name = event.name;
      action.description = event.description;
      action.image = companion.image;
      action.flyer = event.flyer;
      action.location = event.location;
      //Step 4 ist bereits zugewiesen
      //Step 5
      if (companion.visibleFrom === "now") {
        action.showDate = new Date();
      } else {
        action.showDate = companion.singleDateObject.showDate;
      }
      //Step 3
      action.startTime = companion.singleDateObject.startTime;
      action.endTime = companion.singleDateObject.endTime;
      action.startDate = undefined;
      action.endDate = undefined;
      action.activeDays = [];
      action.displayedDays = [];

      if (companion.dateType === "continuous") {
        action.startDate = companion.startDate;
        action.endDate = companion.endDate;
        action.activeDays = companion.activeDays;
        action.activityDates = [
          transformTempTimeLapseToDateItem(companion.singleDateObject),
        ];
        array = [action];

        if (!!companion.displayedDays && companion?.displayedDays.length > 0)
          action.displayedDays = companion.displayedDays;
      } else if (companion.dateType === "single") {
        action.activityDates = [
          transformTempTimeLapseToDateItem(companion.singleDateObject),
        ];
        array = [action];
      } else if (companion.connectedDates) {
        companion.dateList.forEach((date, index) => {
          action.activityDates[index] = transformTempTimeLapseToDateItem(date);
        });
        array = [action];
      } else {
        let createdArray: Action[] = [];
        const seriesId: string = uuidv4();

        companion.dateList.forEach((date, dateIndex) => {
          let localAction: Action = {
            ...action,
            activityDates: [transformTempTimeLapseToDateItem(date)],
            id: dateIndex > 0 ? undefined : action?.id || event.id,
            slugId: dateIndex > 0 ? undefined : action?.slugId || event.slugId,
            seriesId,
            canonUrl: "",
          };

          if (companion.visibleFrom === "deadline") {
            localAction.showDate = companion.dateList[dateIndex].showDate;
          } else if (companion.visibleFrom === "date") {
            localAction.showDate = companion.singleDateObject.showDate;
          }
          createdArray.push(localAction);
        });
        array = [...createdArray];
      }
      break;
    default:
      break;
  }
  return array;
};

//TODO: Texte durch Übersetzungen ersetzen wenn richtige Fortschrittkomponente eingebaut wird

/**
 * Method to manage and handle generation of activities and creation for backend
 * @param axios AxiosInstance
 * @param companion containing all needed data
 * @param toggleProgressComplete toggleTo activate/deactivate popup
 * @param updateProgress setter to increase progress
 */
export const createOrUpdateActivity = async (
  axios: AxiosInstance,
  companion: ActivityEditCompanion,
  toggleProgressComplete: Function,
  updateProgress: Function
): Promise<void> => {
  toggleProgressComplete(false);
  const generatedList: Action[] | Event[] = generateActivityList(companion);
  for (const entry of generatedList) {
    switch (companion.activityType) {
      case ActivityType.ACTION:
        if (!!entry.id) {
          await updateAction(
            axios,
            entry as Action,
            companion?.currentImageFile,
            companion?.currentFlyerFile,
            companion?.currentImagePreviewFile
          )
            .then(() => {
              updateProgress();
            })
            .catch((exc) => {
              console.error("Error during creation/update of activity!", exc);
              toggleProgressComplete(true);
              return [];
            });
        } else {
          await createAction(
            axios,
            entry as Action,
            companion?.currentImageFile,
            companion?.currentFlyerFile,
            companion?.currentImagePreviewFile
          )
            .then(() => {
              updateProgress();
            })
            .catch((exc) => {
              console.error("Error during creation/update of activity!", exc);
              toggleProgressComplete(true);
              return [];
            });
        }
        break;

      case ActivityType.EVENT:
        const updatedFlyerIdEvent: Event = updateFlyerIdFromCompanion(
          companion,
          entry as Event
        );
        const includeFlyerFile: boolean =
          updatedFlyerIdEvent.flyer?.id !== companion.flyer?.id;
        if (!!entry.id) {
          await updateEventOnBackend(
            axios,
            updatedFlyerIdEvent,
            companion?.currentImageFile,
            includeFlyerFile ? companion?.currentFlyerFile : undefined,
            companion?.currentImagePreviewFile
          )
            .then(() => {
              updateProgress();
            })
            .catch((exc) => {
              console.error("Error during creation/update of activity!", exc);
              toggleProgressComplete(true);
              return [];
            });
        } else {
          await createEvent(
            axios,
            entry as Event,
            companion?.currentImageFile,
            companion?.currentFlyerFile,
            companion?.currentImagePreviewFile
          )
            .then(() => {
              updateProgress();
            })
            .catch((exc) => {
              console.error("Error during creation/update of activity!", exc);
              toggleProgressComplete(true);
              return [];
            });
        }
        break;

      default:
        return;
    }
  }
  toggleProgressComplete(true);
  deleteCompanionInLocalStorage(
    process.env.REACT_APP_ACTIVITY_LOCAL_STORAGE_OBJECT!
  );
};

/**
 * Helper to copy an {@link Event} or {@link Action}
 * @param axios network instance
 * @param companion activity edit companion object with needed data
 * @param toggleProgressComplete toggleTo activate/deactivate popup
 * @param updateProgress setter to increase progress
 */
export const copyActivity = async (
  axios: AxiosInstance,
  companion: ActivityEditCompanion,
  toggleProgressComplete: Function,
  updateProgress: Function
): Promise<void> => {
  toggleProgressComplete(false);
  const generatedList: Action[] | Event[] = generateActivityList(companion);
  for (const entry of generatedList) {
    switch (companion.activityType) {
      case ActivityType.ACTION:
        entry.image = companion.action.image;
        entry.flyer = companion.action.flyer;
        await copyAction(axios, entry as Action)
          .then((act) => {
            updateProgress(act);
          })
          .catch((exc) => {
            console.error("Error during copy of activity!", exc);
            toggleProgressComplete(true);
            return [];
          });
        break;

      case ActivityType.EVENT:
        entry.image = companion.event.image;
        entry.flyer = companion.event.flyer;
        await copyEvent(axios, entry as Event)
          .then((evt) => {
            updateProgress(evt);
          })
          .catch((exc) => {
            console.error("Error during creation/update of activity!", exc);
            toggleProgressComplete(true);
            return [];
          });
        break;

      default:
        return;
    }
  }
  toggleProgressComplete(true);
};

/**
 * Method to get correct list of activityTags divided into event- and action-tags
 * @param cat Category of tags to be returned
 * @returns ActivityTags array containg either all event-tags or all action-tags
 * @tested
 */
export const getSeparateActivityLists = (cat: ActivityType): ActivityTag[] => {
  switch (cat) {
    case ActivityType.ACTION:
      return getActionTagList();
    case ActivityType.EVENT:
      return getEventTagList();
    default:
      return [];
  }
};

/**
 * Helper to get List of all Event-Tags
 * @returns ActivityTags that belong to events
 * @tested
 */
export const getEventTagList = (): ActivityTag[] => {
  return [
    ActivityTag.EXHIBITION,
    ActivityTag.FEIER,
    ActivityTag.BENEFIT,
    ActivityTag.COMEDY,
    ActivityTag.CELEBRATION,
    ActivityTag.FESTIVAL,
    ActivityTag.KIRMES,
    ActivityTag.MOVIE,
    ActivityTag.CLOSING,
    ActivityTag.GARAGE_SALE,
    ActivityTag.GUIDED_TOUR,
    ActivityTag.ANNIVERSARY,
    ActivityTag.JUNGSCHAR,
    ActivityTag.CABARET,
    ActivityTag.CHILD_THEATRE,
    ActivityTag.FILM,
    ActivityTag.CINEMA_DAY,
    ActivityTag.CONCERT,
    ActivityTag.COURSE,
    ActivityTag.COST_SAMPLE,
    ActivityTag.READING,
    ActivityTag.MARKET,
    ActivityTag.CITY_FESTIVAL,
    ActivityTag.SHOW_SUNDAY,
    ActivityTag.FAIR,
    ActivityTag.MUSICAL,
    ActivityTag.OPENAIR,
    ActivityTag.OPERA,
    ActivityTag.OPERETTE,
    ActivityTag.PARTY,
    ActivityTag.PREMIER,
    ActivityTag.EDUCATION,
    ActivityTag.REHEARSAL,
    ActivityTag.SEMINAR,
    ActivityTag.SHOW,
    ActivityTag.GAME,
    ActivityTag.SPORT_COURSE,
    ActivityTag.SPORTING_EVENT,
    ActivityTag.OPENDAY,
    ActivityTag.DANCE,
    ActivityTag.DANCE_EVENT,
    ActivityTag.THEATRE,
    ActivityTag.VARIETE,
    ActivityTag.TASTING,
    ActivityTag.VERNISSAGE,
    ActivityTag.DEMONSTRATION,
    ActivityTag.CHRISTMAS_MARKET,
    ActivityTag.WINE_TASTING,
    ActivityTag.FARMERS_MARKET,
    ActivityTag.WORKSHOP,
    ActivityTag.COMPETITION,
    ActivityTag.MODESCHAU,
    ActivityTag.PUBLIC_VIEWING,
    ActivityTag.TV_BROADCAST,
    ActivityTag.SPORTS_BROADCAST,
    ActivityTag.HOLIDAY_PROGRAM,
    ActivityTag.INFO_EVENT,
    ActivityTag.MEETING,
  ];
};

/**
 * Helper to get List of all Action-Tags
 * @returns ActivityTags that belong to actions
 * @tested
 */
export const getActionTagList = (): ActivityTag[] => {
  return [
    ActivityTag.ABVERKAUF,
    ActivityTag.ALL_YOU_CAN_DRINK,
    ActivityTag.ALL_YOU_CAN_EAT,
    ActivityTag.JUNKSALE,
    ActivityTag.OFFER,
    ActivityTag.CLOSURE,
    ActivityTag.BARGAIN_SALE,
    ActivityTag.BRUNCH,
    ActivityTag.CASHBACK,
    ActivityTag.DOUBLETIME,
    ActivityTag.COCKTAIL_SPECIAL,
    ActivityTag.SINGLE_COPY,
    ActivityTag.DRINK_SPECIAL,
    ActivityTag.HAPPY_HOUR,
    ActivityTag.CLEARANCE_SALE,
    ActivityTag.STOCK_SALE,
    ActivityTag.NEW_PRODUCT,
    ActivityTag.NEW_WARE,
    ActivityTag.NEW_ARRIVALS,
    ActivityTag.SAMPLE,
    ActivityTag.SAMPLE_TRAINING,
    ActivityTag.PRODUCT_DEMONSTRATION,
    ActivityTag.SALES_ACTION,
    ActivityTag.CLOSING_DOWN_SALE,
    ActivityTag.RAUS_VERKAUF,
    ActivityTag.REMAINDER,
    ActivityTag.SALE,
    ActivityTag.CLOSING_OUT_SALE,
    ActivityTag.BARGAIN_MARKET,
    ActivityTag.TASTER_ACTION,
    ActivityTag.TASTER_COURSE,
    ActivityTag.SPECIAL_ITEM,
    ActivityTag.SPECIAL_ACTION,
    ActivityTag.SPECIAL,
    ActivityTag.SSV,
    ActivityTag.TRAINING,
    ActivityTag.WSV,
    ActivityTag.MENU,
    ActivityTag.SPEISEAKTION,
    ActivityTag.FOODSPECIAL,
    ActivityTag.ESSENSANGEBOT,
  ];
};

/**
 * Helper to return a list with all activityTags belonging to EAT_AND_DRINK Category
 * @returns list of activity tags that fall under EAT_AND_DRINK
 * @tested
 */
export const getEatAndDrinkTagList = (): ActivityTag[] => {
  return [
    ActivityTag.COST_SAMPLE,
    ActivityTag.TASTING,
    ActivityTag.WINE_TASTING,
    ActivityTag.ALL_YOU_CAN_DRINK,
    ActivityTag.ALL_YOU_CAN_EAT,
    ActivityTag.BRUNCH,
    ActivityTag.DOUBLETIME,
    ActivityTag.DRINK_SPECIAL,
    ActivityTag.COCKTAIL_SPECIAL,
    ActivityTag.HAPPY_HOUR,
    ActivityTag.MENU,
    ActivityTag.SPEISEAKTION,
    ActivityTag.FOODSPECIAL,
    ActivityTag.ESSENSANGEBOT,
  ];
};

/**
 * Helper to return a list with all activityTags belonging to TRADE_AND_SERVICE Category
 * @returns list of activity tags that fall under TRADE_AND_SERVICE
 * @tested
 */
export const getTradeAndServiceTagList = (): ActivityTag[] => {
  return [
    ActivityTag.GARAGE_SALE,
    ActivityTag.COURSE,
    ActivityTag.MARKET,
    ActivityTag.FAIR,
    ActivityTag.EDUCATION,
    ActivityTag.SEMINAR,
    ActivityTag.FARMERS_MARKET,
    ActivityTag.WORKSHOP,
    ActivityTag.ABVERKAUF,
    ActivityTag.JUNKSALE,
    ActivityTag.BARGAIN_SALE,
    ActivityTag.OFFER,
    ActivityTag.SAMPLE,
    ActivityTag.CLOSURE,
    ActivityTag.CASHBACK,
    ActivityTag.SINGLE_COPY,
    ActivityTag.CLEARANCE_SALE,
    ActivityTag.STOCK_SALE,
    ActivityTag.NEW_ARRIVALS,
    ActivityTag.NEW_PRODUCT,
    ActivityTag.NEW_WARE,
    ActivityTag.PRODUCT_DEMONSTRATION,
    ActivityTag.SALES_ACTION,
    ActivityTag.RAUS_VERKAUF,
    ActivityTag.REMAINDER,
    ActivityTag.CLOSING_DOWN_SALE,
    ActivityTag.SALE,
    ActivityTag.CLOSING_OUT_SALE,
    ActivityTag.BARGAIN_MARKET,
    ActivityTag.SPECIAL_ACTION,
    ActivityTag.SPECIAL_ITEM,
    ActivityTag.SPECIAL,
    ActivityTag.SSV,
    ActivityTag.WSV,
    ActivityTag.MODESCHAU,
  ];
};

/**
 * Helper to return a list with all activityTags belonging to EXPERIENCE Category
 * @returns list of activity tags that fall under EXPERIENCE
 * @tested
 */
export const getExperienceTagList = (): ActivityTag[] => {
  return [
    ActivityTag.EXHIBITION,
    ActivityTag.REHEARSAL,
    ActivityTag.BENEFIT,
    ActivityTag.COMEDY,
    ActivityTag.FEIER,
    ActivityTag.PARTY,
    ActivityTag.TRAINING,
    ActivityTag.FESTIVAL,
    ActivityTag.FILM,
    ActivityTag.CLOSING,
    ActivityTag.GUIDED_TOUR,
    ActivityTag.ANNIVERSARY,
    ActivityTag.JUNGSCHAR,
    ActivityTag.CABARET,
    ActivityTag.CHILD_THEATRE,
    ActivityTag.MOVIE,
    ActivityTag.KIRMES,
    ActivityTag.CONCERT,
    ActivityTag.READING,
    ActivityTag.MUSICAL,
    ActivityTag.OPENAIR,
    ActivityTag.OPERA,
    ActivityTag.OPERETTE,
    ActivityTag.CELEBRATION,
    ActivityTag.PREMIER,
    ActivityTag.SHOW_SUNDAY,
    ActivityTag.SHOW,
    ActivityTag.GAME,
    ActivityTag.SPORT_COURSE,
    ActivityTag.SPORTING_EVENT,
    ActivityTag.CITY_FESTIVAL,
    ActivityTag.OPENDAY,
    ActivityTag.DANCE,
    ActivityTag.DANCE_EVENT,
    ActivityTag.THEATRE,
    ActivityTag.VARIETE,
    ActivityTag.VERNISSAGE,
    ActivityTag.DEMONSTRATION,
    ActivityTag.CHRISTMAS_MARKET,
    ActivityTag.CINEMA_DAY,
    ActivityTag.SAMPLE_TRAINING,
    ActivityTag.TASTER_ACTION,
    ActivityTag.TASTER_COURSE,
    ActivityTag.MEETING,
    ActivityTag.COMPETITION,
    ActivityTag.PUBLIC_VIEWING,
    ActivityTag.TV_BROADCAST,
    ActivityTag.SPORTS_BROADCAST,
    ActivityTag.HOLIDAY_PROGRAM,
    ActivityTag.INFO_EVENT,
  ];
};

/**
 * Method to return correct Category depending on given tag
 * @param tag used for selection category
 * @returns Category selected or undefined if no corresponding Category was found
 * @tested
 */
export const checkCategoryForActivityTag = (
  tag: ActivityTag
): Category | undefined => {
  const eatAndDrinkList = getEatAndDrinkTagList();
  const tradeAndServiceList = getTradeAndServiceTagList();
  const experienceList = getExperienceTagList();
  if (eatAndDrinkList.includes(tag)) return Category.EAT_DRINK;
  if (tradeAndServiceList.includes(tag)) return Category.SHOPPING;
  if (experienceList.includes(tag)) return Category.EXPERIENCE;
  return undefined;
};

/**
 * Helper to get List of all Action-Tags
 * @returns ActivityTags that belong to actios
 * @tested
 */
export const getCorrectDetailTagListForMainTag = (
  mainTag: ActivityTag
): DetailFilter[] => {
  switch (mainTag) {
    case ActivityTag.CONCERT:
    case ActivityTag.FESTIVAL:
    case ActivityTag.OPENAIR:
    case ActivityTag.PARTY:
    case ActivityTag.DANCE:
      return [
        DetailFilter.CLASSIC,
        DetailFilter.JAZZ,
        DetailFilter.POP,
        DetailFilter.ROCK,
        DetailFilter.RAP,
        DetailFilter.ALTERNATIVE,
        DetailFilter.METAL,
        DetailFilter.EDM,
      ];

    case ActivityTag.DEMONSTRATION:
      return [
        DetailFilter.CLASSIC,
        DetailFilter.JAZZ,
        DetailFilter.POP,
        DetailFilter.ROCK,
        DetailFilter.RAP,
        DetailFilter.ALTERNATIVE,
        DetailFilter.METAL,
        DetailFilter.EDM,
        DetailFilter.ACTION,
        DetailFilter.HORROR,
        DetailFilter.DRAMA,
        DetailFilter.COMEDY,
        DetailFilter.THRILLER,
        DetailFilter.CHILDREN,
        DetailFilter.FANTASY,
        DetailFilter.DOCUMENTATION,
        DetailFilter.SCIFI,
      ];
    case ActivityTag.EXHIBITION:
    case ActivityTag.CLOSING:
    case ActivityTag.VERNISSAGE:
    case ActivityTag.INFO_EVENT:
      return [
        DetailFilter.ART,
        DetailFilter.TECHNIC,
        DetailFilter.MODELLING,
        DetailFilter.HISTORY,
      ];
    case ActivityTag.MOVIE:
    case ActivityTag.FILM:
    case ActivityTag.SHOW:
    case ActivityTag.THEATRE:
      return [
        DetailFilter.ACTION,
        DetailFilter.HORROR,
        DetailFilter.DRAMA,
        DetailFilter.COMEDY,
        DetailFilter.THRILLER,
        DetailFilter.CHILDREN,
        DetailFilter.FANTASY,
        DetailFilter.DOCUMENTATION,
        DetailFilter.SCIFI,
      ];
    case ActivityTag.GAME:
    case ActivityTag.SPORTING_EVENT:
    case ActivityTag.SPORT_COURSE:
      return [
        DetailFilter.SOCCER,
        DetailFilter.TENNIS,
        DetailFilter.VOLLEYBALL,
        DetailFilter.BASKETBALL,
        DetailFilter.SWIMMING,
        DetailFilter.GYMNASTICS,
        DetailFilter.DANCING,
        DetailFilter.CONTEST,
        DetailFilter.FOOTBALL,
        DetailFilter.WATERPOLO,
        DetailFilter.BILLIARD,
        DetailFilter.DART,
        DetailFilter.TABLE_TENNIS,
        DetailFilter.ATHLETICS,
        DetailFilter.FITNESS,
        DetailFilter.MARTIAL_ARTS,
        DetailFilter.COMPETITION,
        DetailFilter.SQUASH,
        DetailFilter.NINEPINS,
        DetailFilter.BATMINTON,
        DetailFilter.RIDING,
        DetailFilter.BIKEBALL,
        DetailFilter.HANDBALL,
        DetailFilter.MOTORSPORT,
        DetailFilter.YOGA,
      ];
    case ActivityTag.COURSE:
    case ActivityTag.WORKSHOP:
    case ActivityTag.SEMINAR:
    case ActivityTag.EDUCATION:
      return [
        DetailFilter.COOKING,
        DetailFilter.BACKING,
        DetailFilter.LANGUAGE,
        DetailFilter.FURTHER_EDUCATION,
        DetailFilter.ADVANCED_TRAINING,
        DetailFilter.LITERATURE,
        DetailFilter.ART,
        DetailFilter.PAINTING,
        DetailFilter.DRAWING,
        DetailFilter.CRAFT,
      ];
    case ActivityTag.BENEFIT:
      return [
        DetailFilter.SPORT_BENEFIT,
        DetailFilter.BENEFIT_EVENT,
        DetailFilter.BENEFIT_ACTION,
        DetailFilter.BENEFIT_CONCERT,
        DetailFilter.BENEFIT_FESTIVAL,
        DetailFilter.BENEFIT_SALE,
      ];
    case ActivityTag.CELEBRATION:
    case ActivityTag.FEIER:
      return [
        DetailFilter.CLUB_FESTIVAL,
        DetailFilter.CITY_FESTIVAL,
        DetailFilter.DISTRICT_FESTIVAL,
        DetailFilter.VILLAGE_FESTIVAL,
      ];
    case ActivityTag.GUIDED_TOUR:
      return [
        DetailFilter.CITY_TOUR,
        DetailFilter.EXCURSION,
        DetailFilter.HISTORICAL,
        DetailFilter.ARCHAEOLOGICAL,
        DetailFilter.THEMED_TOUR,
        DetailFilter.NATURE_TOUR,
      ];
    case ActivityTag.PREMIER:
      return [
        DetailFilter.MOVIE,
        DetailFilter.THEATRE,
        DetailFilter.MUSICAL,
        DetailFilter.MUSIC,
        DetailFilter.VIDEO,
      ];
    case ActivityTag.JUNKSALE:
    case ActivityTag.OFFER:
    case ActivityTag.CLOSURE:
    case ActivityTag.BARGAIN_SALE:
    case ActivityTag.CASHBACK:
    case ActivityTag.SINGLE_COPY:
    case ActivityTag.CLEARANCE_SALE:
    case ActivityTag.STOCK_SALE:
    case ActivityTag.NEW_PRODUCT:
    case ActivityTag.NEW_WARE:
    case ActivityTag.NEW_ARRIVALS:
    case ActivityTag.PRODUCT_DEMONSTRATION:
    case ActivityTag.SALES_ACTION:
    case ActivityTag.CLOSING_DOWN_SALE:
    case ActivityTag.RAUS_VERKAUF:
    case ActivityTag.REMAINDER:
    case ActivityTag.SALE:
    case ActivityTag.CLOSING_OUT_SALE:
    case ActivityTag.BARGAIN_MARKET:
    case ActivityTag.SPECIAL_ITEM:
    case ActivityTag.SPECIAL_ACTION:
    case ActivityTag.SPECIAL:
    case ActivityTag.SSV:
    case ActivityTag.WSV:
      return [
        DetailFilter.ANTIQUES,
        DetailFilter.BABYWARES,
        DetailFilter.BUILDING_SUPPLIES,
        DetailFilter.BEAUTY,
        DetailFilter.WEDDINGDRESS,
        DetailFilter.GLASSES,
        DetailFilter.BOOKS,
        DetailFilter.OFFICE_SUPPLIES,
        DetailFilter.CAMPING_SUPPLIES,
        DetailFilter.DECORATIONS,
        DetailFilter.IRON_WARE,
        DetailFilter.ELECTRO,
        DetailFilter.EROTIC,
        DetailFilter.PHOTOGRAPHY,
        DetailFilter.HAIR_STYLES,
        DetailFilter.BEVERAGES,
        DetailFilter.GLASS_WARE,
        DetailFilter.HOUSEHOLD_TOOLS,
        DetailFilter.HOUSEHOLD_WARES,
        DetailFilter.HANDYMAN_SUPPLIES,
        DetailFilter.HEARING_ACOUSTICS,
        DetailFilter.JEWELER,
        DetailFilter.CHILDREN_SUPPLIES,
        DetailFilter.NEAR_BODY_SERVICES,
        DetailFilter.COSMETICS,
        DetailFilter.DRY_GOODS,
        DetailFilter.GROCERIES,
        DetailFilter.LEATHER_WARES,
        DetailFilter.LAMPS,
        DetailFilter.MASSAGE,
        DetailFilter.FURNITURE,
        DetailFilter.FASHION,
        DetailFilter.MUSICAL_INSTRUMENTS,
        DetailFilter.OPTICS,
        DetailFilter.PARFUME,
        DetailFilter.TRAVELLING,
        DetailFilter.LOCKING_SYSTEMS,
        DetailFilter.LOCKSMITH,
        DetailFilter.JEWELERY,
        DetailFilter.STATIONERY,
        DetailFilter.SHOES,
        DetailFilter.TOYS,
        DetailFilter.SPORTS_GOODS,
        DetailFilter.TOBACCO,
        DetailFilter.BAGS,
        DetailFilter.TATTOO,
        DetailFilter.ANIMAL_SUPPLIES,
        DetailFilter.PET_FOOD,
        DetailFilter.RECORDS,
        DetailFilter.CLOCKS,
        DetailFilter.ENTERTAINMENT_SYSTEMS,
        DetailFilter.WELLNESS,
        DetailFilter.TOOLS,
        DetailFilter.WOOL,
        DetailFilter.MAGAZINES,
        DetailFilter.NEWSPAPER,
        DetailFilter.ZOO_ARTICLES,
        DetailFilter.PASTIRES,
        DetailFilter.MEAT_PRODUCTS,
        DetailFilter.SAUSAGE_PRODUCTS,
        DetailFilter.ACCESSORIES,
        DetailFilter.CRAFT_SUPPLIES,
        DetailFilter.COMPUTER,
        DetailFilter.MOVIES,
        DetailFilter.PC_GAMES,
        DetailFilter.CONSOLE_GAMES,
        DetailFilter.SOUVENIR,
        DetailFilter.HOME_AND_GARDEN,
      ];
    case ActivityTag.COMPETITION:
      return [
        DetailFilter.AUSSCHREIBUNG,
        DetailFilter.CUP,
        DetailFilter.GEWINNSPIEL,
        DetailFilter.CONTEST,
        DetailFilter.LOTTERY,
        DetailFilter.PREISAUSSCHREIBEN,
        DetailFilter.TURNIER,
      ];
    default:
      return [];
  }
};

/**
 * Helper to determine whether given tag is part of eventTags
 * @param tag tag ot test
 * @returns true when included in eventTags
 * @tested
 */
export const isEventTag = (tag: ActivityTag): boolean => {
  return getEventTagList().includes(tag);
};

/**
 * Helper to determine whether given tag is part of actionTags
 * @param tag tag ot test
 * @returns true when included in actionTags
 * @tested
 */
export const isActionTag = (tag: ActivityTag): boolean => {
  return getActionTagList().includes(tag);
};

/**
 * Helper to generate a list of tag-elements from ActivityTag-array
 * @param tagNames list of ActivityTags-elements
 * @returns list of Tag-Elements generated from tagNames
 * @tested
 */
export const generateActivityTagList = (tagNames: ActivityTag[]): Tag[] => {
  let generatedTags: Tag[] = [];
  tagNames.forEach((tag: ActivityTag) => {
    generatedTags.push({
      value: tag as string,
      title: i18n.t(`enum.activityTags.${tag as string}`),
    });
  });
  return generatedTags;
};

/**
 * Helper to generate a list of tag-elements from DetailFilters-Array
 * @param tagNames list of DetailFilters-elements
 * @returns list of Tag-Elements generated from tagNames
 * @tested
 */
export const generateActivityDetailTagList = (tagNames: DetailFilter[]) => {
  let generatedTags: Tag[] = [];
  tagNames.forEach((tag: DetailFilter) => {
    generatedTags.push({
      value: tag as string,
      title: i18n.t(`enum.activityDetails.${tag as string}`),
    });
  });
  return generatedTags;
};

/**
 * Helper to generate searchValueSuggestions for tag-search
 * @param searchValue value for search
 * @returns array of suggestions
 * @tested
 */
export const generateSearchValueSuggestions = (
  searchValue: string,
  listToSearch: Tag[]
): DropdownOption[] => {
  if (searchValue.trim() === "") return [];
  let filteredList = listToSearch.filter((entry: Tag) => {
    return entry.title
      .trim()
      .toLowerCase()
      .includes(searchValue.trim().toLowerCase());
  });
  let accumulatedResults: DropdownOption[] = [];
  filteredList.forEach((entry) => {
    accumulatedResults.push({ label: entry.title, value: entry.title });
  });
  return accumulatedResults;
};

/**
 * Helper to generate correct ImageData depending on type of activity
 * @param activityCompanion activityEditCompanion object containing necessary data
 * @returns ImageData object containing fileName and fileType
 * @tested
 */
export const getCorrectImageDataForActivityType = (
  isPreview: boolean,
  activityCompanion: ActivityEditCompanion
): ImageData => {
  switch (activityCompanion.activityType) {
    case ActivityType.ACTION:
      return {
        fileName:
          (isPreview
            ? activityCompanion.action?.previewImage?.filename
            : activityCompanion.action?.image?.filename) ?? "",
        fileType: FileType.ACTION,
      };

    case ActivityType.EVENT:
      return {
        fileName:
          (isPreview
            ? activityCompanion.event?.previewImage?.filename
            : activityCompanion.event?.image?.filename) ?? "",
        fileType: FileType.EVENT,
      };

    default:
      return {
        fileName: "",
        fileType: FileType.EVENT,
      };
  }
};

/**
 * Helper to generate Array containing all TargetAudience values
 * @returns said array containing all TargetAudience values
 * @tested
 */
export const getAllTargetAudiences = (): TargetAudience[] => {
  return [
    TargetAudience.WITH_ANIMALS,
    TargetAudience.WITH_CHILDREN,
    TargetAudience.WITH_DISABILITY,
    TargetAudience.WITH_ELDERS,
  ];
};

/**
 * Helper to generate tags for Target-Audience
 * @returns Tag-array containg tags with translated values
 * @tested
 */
export const generateTargetAudienceTags = (): Tag[] => {
  let generatedList: Tag[] = [];
  getAllTargetAudiences().forEach((tA) => {
    generatedList.push({
      title: i18n.t(`enum.targetAudience.${tA}`),
      value: tA,
    });
  });
  return generatedList;
};

/**
 *
 * @param companion
 * @param generateDateRows
 * @returns
 */
export const generateMultiDateTable = (
  companion: ActivityEditCompanion,
  generateDateRows: TableRow[]
): JSX.Element => {
  if (companion.dateList.length > 0 && companion.dateType === "multi")
    return (
      <>
        <div>{i18n.t("adminEventPage.event.dateEdit.dateList")}</div>
        <TableComponent
          key={companion.dateList[companion.dateList.length - 1].id}
          header={i18n.t("adminEventPage.event.dateEdit.dateTableHeader", {
            returnObjects: true,
          })}
          rows={generateDateRows}
        />
      </>
    );
  else return <></>;
};

/**
 * Method to generate JSX.Element containg info about step1
 * @param activityCompanion activityEditCompanion containing all required information
 * @returns JSX.Element containing all necessary information
 */
export const generateCorrectTagReplacementText = (
  activityCompanion: ActivityEditCompanion
): JSX.Element => {
  return (
    <div className="unselected-content">
      <p>
        {i18n.t(
          `adminEventPage.type${
            activityCompanion.activityType === ActivityType.EVENT
              ? "Event"
              : "Action"
          }`
        )}
        <br />
        {i18n.t("adminEventPage.event.steps.step2.replacementType")}
        {i18n.t(`enum.activityTags.${activityCompanion.mainTag}`)}
        <br />
        {i18n.t("adminEventPage.event.steps.step2.replacementCat")}
        {i18n.t(`enum.category.${activityCompanion.category}`)}
      </p>
    </div>
  );
};

/**
 * Helper to determine if activity is Event
 * @param activity activity to check
 * @returns boolean when activity is event
 * @tested
 */
export const isEvent = (activity: Event | Action): activity is Event => {
  return isEventTag(activity.mainTag);
};

/**
 * Helper to determine if activity is Action
 * @param activity activity to check
 * @returns boolean when activity is Action
 * @tested
 */
export const isAction = (activity: Event | Action): activity is Action => {
  return isActionTag(activity.mainTag);
};

/**
 * Helper to generate new Date for now
 * @param now optional for test purpose
 * @returns generated date
 */
export const getNow = (now: Date = new Date()): Date => {
  now = new Date(now);
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
};

/**
 * Helper to determine if an activity is in the future
 * @param activity item to check
 * @returns boolean true if activity is in future
 * @tested
 */
export const isActivityInTheFuture = (
  activity: Event | Action,
  now: Date = new Date()
): boolean => {
  now = getNow(now);

  switch (true) {
    case !!activity.startDate &&
      new Date(activity.startDate).getTime() - now.getTime() > 0:
    // eslint-disable-next-line
    case !!activity.startDate &&
      new Date(activity.startDate).getTime() - now.getTime() < 0 &&
      !!activity.endDate &&
      new Date(activity.endDate).getTime() - now.getTime() > 0:
    // eslint-disable-next-line
    case activity.activityDates.length > 0 &&
      activity.activityDates.filter(
        (item) => new Date(item.date).getTime() - now.getTime() > 0
      ).length > 0:
      return true;

    default:
      return false;
  }
};

/**
 * Method to save activityCompanion in localStorage
 * @param activityCompanion EventEditCompanion to be saved in localStorage
 */
export const saveCompanionInLocalStorage = (
  activityCompanion: ActivityEditCompanion | undefined
): void => {
  if (!localStorage) return;
  try {
    localStorage.setItem(
      process.env.REACT_APP_ACTIVITY_LOCAL_STORAGE_OBJECT!,
      JSON.stringify(activityCompanion)
    );
  } catch (error) {
    console.error(
      "error during store activity companion object on local storage",
      error
    );
    localStorage.removeItem(
      process.env.REACT_APP_ACTIVITY_LOCAL_STORAGE_OBJECT!
    );
  }
};

/**
 * Method to delete item from localStorage
 * @param key of item to be deleted
 */
export const deleteCompanionInLocalStorage = (key: string) => {
  if (localStorage) localStorage.removeItem(key);
};

/**
 * Helper to generate list of all given activities with date string as label and id as value
 * @param activities to be transformed into drpdownoptions
 * @returns dropdownoptions for all given activities
 */
export const generateActivityDropdownList = (
  activities: (Action | Event)[],
  activityType: ActivityType
): DropdownOption[] => {
  let dropdownOptions: DropdownOption[] = [];
  sortActivitiesByActivityDate(activities, activityType).forEach((activity) => {
    dropdownOptions.push({
      label: createImprovedActivityDateString(activity),
      value: activity.id!,
    });
  });

  return dropdownOptions;
};

/**
 * Method to return ButtonProps depending on given type with given method
 * @param type PopupType that decided what config will be returned
 * @param abortSelection method that closes the Popup
 * @param handleSpecificButton method to handle the specific button-click for each case
 * @param currentActivity currentActivity relevant for diabled buttons
 * @returns ButtonComponentProps depending on given type
 */
export const generateCorrectButtonBottomsForCombinedPopupType = (
  type: PopupType,
  abortSelection: Function,
  handleSpecificButton: Function,
  currentActivity?: Event | Action
): ButtonComponentProps[] => {
  let generatedButtons: ButtonComponentProps[] = [
    {
      value: i18n.t("adminConfigurationPage.buttons.cancel"),
      onClick: () => abortSelection(),
    },
    {
      value:
        type === PopupType.DELETE
          ? i18n.t("adminEventPage.event.delete")
          : type === PopupType.DETAIL
          ? i18n.t("adminEventPage.event.editDateState")
          : type === PopupType.DUPLICATE
          ? i18n.t("adminEventPage.event.duplicateEvent")
          : type === PopupType.EDIT
          ? i18n.t("adminEventPage.event.editEvent")
          : "",
      disabled: !currentActivity,
      onClick: () => handleSpecificButton(),
    },
  ];
  return generatedButtons;
};

/**
 * Method to generate string depending on PopupType given
 * @param type PopupType to determine what will be returned
 * @returns string depending on given PopupType
 * @tested
 */
export const createCorrectEncoreTextForType = (type: PopupType): string => {
  switch (type) {
    case PopupType.EDIT:
      return i18n.t("adminEventPage.event.popup.edit");
    case PopupType.DELETE:
      return i18n.t("adminEventPage.event.popup.delete");
    case PopupType.DETAIL:
      return i18n.t("adminEventPage.event.popup.detail");
    case PopupType.DUPLICATE:
      return i18n.t("adminEventPage.event.popup.duplicate");
    default:
      return "";
  }
};

/**
 * Method to generate content for combinedPopup depending on given type
 * @param type Type of the Popup, that determines the returned content
 * @param onDelete Function that will be executed for deletion
 * @returns JSX.Element containing type-specific content
 * @tested
 */
export const generateSpecificCombinedPopupContent = (
  type: PopupType,
  onDelete: Function
): JSX.Element => {
  switch (type) {
    case PopupType.DELETE:
      return (
        <ButtonComponent
          value={i18n?.t("adminEventPage.deleteAllTitle")}
          onClick={() => onDelete()}
        />
      );
    case PopupType.EDIT:
    case PopupType.DUPLICATE:
    case PopupType.DETAIL:
    default:
      return <></>;
  }
};

/**
 * Helper to sort {@link Event}s or {@link Action}s by the date and correct version
 * @param activities list of {@link Event}s or {@link Action}s
 * @param activityType porperty that changes handling
 * @returns sorted {@link Event} or {@link Action}s array by activity date
 * @tested
 */
export const sortActivitiesByActivityDate = (
  activities: (Event | Action)[],
  activityType: ActivityType
): (Action | Event)[] => {
  let localActivityList: (Event | Action)[] = [...activities];
  return localActivityList.sort(
    (activityA, activityB) =>
      new Date(
        getCorrectActivityDateFromVersion(activityA, activityType)!
      ).getTime() -
      new Date(
        getCorrectActivityDateFromVersion(activityB, activityType)!
      ).getTime()
  );
};

/**
 * Helper to get correct Date from activity by checking event.version
 * @param activity to be checked for version
 * @param activityIsEvent porperty that can be used to force eventHandling
 * @returns first activityDates entry when version 2, first eventDateList entry when version 1 and specific date when other version
 * @tested
 */
export const getCorrectActivityDateFromVersion = (
  activity: Event | Action | undefined,
  activityType: ActivityType,
  index?: number
): Date | undefined => {
  if (!activity) return undefined;
  switch (activityType) {
    case ActivityType.EVENT:
      const event = activity as Event;
      switch (true) {
        case event?.version === 2 && event.activityDates.length > 0:
          return event?.activityDates[index || 0]?.date || undefined;
        case event?.version === 1 && event.eventDateList.length > 0:
          return event?.eventDateList[index || 0] || undefined;

        case event?.version === 1 && event?.eventDateList.length === 0:
        case event?.version === 0:
        default:
          return event?.eventDate || undefined;
      }

    case ActivityType.ACTION:
      const action = activity as Action;
      switch (true) {
        case action?.version === 2 && action.activityDates.length > 0:
          return action?.activityDates[index || 0]?.date || undefined;
        case action?.version === 1 &&
          !!action.actionDates &&
          action.actionDates.length > 0:
          return action.actionDates![index || 0] || undefined;

        case action?.version === 1 && action?.actionDates?.length === 0:
        case action?.version === 0:
        default:
          return action?.startDate || undefined;
      }
    default:
      return undefined;
  }
};

/**
 * Helper to convert an Activity to an EditCompanionObject
 * @param providerSlugId id of current provider
 * @param event optional event data
 * @param action optional action data
 * @returns generated EditCompanionObject
 * @tested
 */
export const convertLocalActivityToCompanionObject = (
  providerSlugId: string,
  event?: Event,
  action?: Action
): ActivityEditCompanion => {
  let currentCompanionObjectInCreation: ActivityEditCompanion =
    createEmptyActivityEditCompanion(
      event || undefined,
      action || undefined,
      providerSlugId
    );

  currentCompanionObjectInCreation.activityType = action
    ? ActivityType.ACTION
    : event
    ? ActivityType.EVENT
    : undefined;

  currentCompanionObjectInCreation.mainTag = !!event?.mainTag
    ? event?.mainTag
    : !!action?.mainTag
    ? action?.mainTag
    : undefined;

  currentCompanionObjectInCreation.category = !!event?.category
    ? event?.category
    : !!action?.categoryItem
    ? action?.categoryItem
    : undefined;

  currentCompanionObjectInCreation.detailTags =
    event && event.detailTags.length > 0
      ? event?.detailTags
      : action && action.detailTags.length > 0
      ? action?.detailTags
      : [];

  currentCompanionObjectInCreation.customTags =
    event && event.customTags.length > 0
      ? event?.customTags
      : action && action.customTags.length > 0
      ? action?.customTags
      : [];

  currentCompanionObjectInCreation.targetAudiences =
    event?.targetAudiences && event.targetAudiences.length > 0
      ? event?.targetAudiences
      : action?.targetAudiences && action.targetAudiences.length > 0
      ? action?.targetAudiences
      : [];

  currentCompanionObjectInCreation.activeDays = !!action?.activeDays
    ? action?.activeDays
    : !!event?.activeDays
    ? event?.activeDays
    : [];

  currentCompanionObjectInCreation.startDate = !!action?.startDate
    ? action.startDate
    : event?.startDate
    ? event.startDate
    : undefined;

  currentCompanionObjectInCreation.endDate = !!action?.endDate
    ? action.endDate
    : event?.endDate
    ? event.endDate
    : undefined;

  currentCompanionObjectInCreation.image = !!action?.image
    ? action.image
    : !!event?.image
    ? event.image
    : undefined;

  currentCompanionObjectInCreation.previewImage = !!action?.previewImage
    ? action.previewImage
    : !!event?.previewImage
    ? event.previewImage
    : undefined;

  currentCompanionObjectInCreation.flyer = !!action?.flyer
    ? action.flyer
    : !!event?.flyer
    ? event.flyer
    : undefined;

  currentCompanionObjectInCreation.activeDays = !!action?.activeDays
    ? action.activeDays
    : !!event?.activeDays
    ? event.activeDays
    : [];

  currentCompanionObjectInCreation.displayedDays = !!action?.displayedDays
    ? action.displayedDays
    : !!event?.displayedDays
    ? event.displayedDays
    : [];

  if (
    (!!action?.startDate && !!action.endDate) ||
    (!!event?.startDate && !!event.endDate)
  ) {
    currentCompanionObjectInCreation.dateType = "continuous";
  }

  if (
    (action?.activityDates ?? []).length > 1 ||
    (event?.activityDates ?? []).length > 1
  ) {
    currentCompanionObjectInCreation.dateType = "multi";
    let convertedDateItemList: TempTimelapse[] = [];
    if (!!action?.activityDates) {
      action?.activityDates.forEach((item, itemIndex) =>
        convertedDateItemList.push({
          endTime: item.endTime,
          id: itemIndex.toFixed(0),
          link: "",
          startTime: item.startTime,
          eventDate: item.date,
          showDate: item.showDate,
        })
      );
    }
    if (!!event?.activityDates) {
      event?.activityDates.forEach((item, itemIndex) =>
        convertedDateItemList.push({
          endTime: item.endTime,
          id: itemIndex.toFixed(0),
          link: "",
          startTime: item.startTime,
          eventDate: item.date,
          showDate: item.showDate,
        })
      );
    }
    currentCompanionObjectInCreation.dateList = convertedDateItemList;
  }

  // if it is a single activity
  let currentSingleDateObject = createEmptyTempTimelapse();
  currentSingleDateObject.showDate = !!action?.showDate
    ? action.showDate
    : !!event?.showDate
    ? event.showDate
    : undefined;
  if (
    (!!action?.activityDates && action?.activityDates.length > 0) ||
    (!!event?.activityDates && event?.activityDates.length > 0)
  ) {
    currentSingleDateObject.eventDate = !!action?.activityDates[0]?.date
      ? action.activityDates[0].date
      : !!event?.activityDates[0]?.date
      ? event.activityDates[0].date
      : undefined;
    currentSingleDateObject.startTime = !!action?.activityDates[0]?.startTime
      ? action.activityDates[0].startTime
      : !!event?.activityDates[0]?.startTime
      ? event.activityDates[0].startTime
      : "";
    currentSingleDateObject.endTime = !!action?.activityDates[0]?.endTime
      ? action.activityDates[0].endTime
      : !!event?.activityDates[0]?.endTime
      ? event.activityDates[0].endTime
      : "";
  }
  currentCompanionObjectInCreation.singleDateObject = currentSingleDateObject;

  currentCompanionObjectInCreation.visibleFrom = "now";
  const now: Date = new Date();
  if (
    (!!action?.showDate &&
      new Date(action?.showDate).getTime() - now.getTime() > 0) ||
    (!!event?.showDate &&
      new Date(event?.showDate).getTime() - now.getTime() > 0)
  )
    currentCompanionObjectInCreation.visibleFrom = "date";

  return currentCompanionObjectInCreation;
};
