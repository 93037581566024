import { Action } from "../action/Action.types";
import { FileType } from "../appConfig/config.types";
import { Event } from "../event/Event.types";

export enum ActivityType {
  EVENT = "EVENT",
  ACTION = "ACTION",
}

export enum ActivityTag {
  EXHIBITION = "EXHIBITION",
  BENEFIT = "BENEFIT",
  FEIER = "FEIER",
  COMEDY = "COMEDY",
  CELEBRATION = "CELEBRATION",
  FESTIVAL = "FESTIVAL",
  MOVIE = "MOVIE",
  CLOSING = "CLOSING",
  GARAGE_SALE = "GARAGE_SALE",
  GUIDED_TOUR = "GUIDED_TOUR",
  ANNIVERSARY = "ANNIVERSARY",
  JUNGSCHAR = "JUNGSCHAR",
  CABARET = "CABARET",
  KIRMES = "KIRMES",
  CHILD_THEATRE = "CHILD_THEATRE",
  FILM = "FILM",
  CINEMA_DAY = "CINEMA_DAY",
  CONCERT = "CONCERT",
  COURSE = "COURSE",
  READING = "READING",
  MARKET = "MARKET",
  REHEARSAL = "REHEARSAL",
  COST_SAMPLE = "COST_SAMPLE",
  SHOW_SUNDAY = "SHOW_SUNDAY",
  FAIR = "FAIR",
  MUSICAL = "MUSICAL",
  OPENAIR = "OPENAIR",
  OPERA = "OPERA",
  OPERETTE = "OPERETTE",
  PARTY = "PARTY",
  PREMIER = "PREMIER",
  CITY_FESTIVAL = "CITY_FESTIVAL",
  EDUCATION = "EDUCATION",
  SEMINAR = "SEMINAR",
  SHOW = "SHOW",
  OTHER_EVENT = "OTHER_EVENT",
  GAME = "GAME",
  SPORT_COURSE = "SPORT_COURSE",
  SPORTING_EVENT = "SPORTING_EVENT",
  OPENDAY = "OPENDAY",
  DANCE = "DANCE",
  DANCE_EVENT = "DANCE_EVENT",
  THEATRE = "THEATRE",
  VARIETE = "VARIETE",
  TASTING = "TASTING",
  VERNISSAGE = "VERNISSAGE",
  DEMONSTRATION = "DEMONSTRATION",
  CHRISTMAS_MARKET = "CHRISTMAS_MARKET",
  WINE_TASTING = "WINE_TASTING",
  FARMERS_MARKET = "FARMERS_MARKET",
  WORKSHOP = "WORKSHOP",
  ABVERKAUF = "ABVERKAUF",
  ALL_YOU_CAN_DRINK = "ALL_YOU_CAN_DRINK",
  ALL_YOU_CAN_EAT = "ALL_YOU_CAN_EAT",
  JUNKSALE = "JUNKSALE",
  OFFER = "OFFER",
  CLOSURE = "CLOSURE",
  BARGAIN_SALE = "BARGAIN_SALE",
  BRUNCH = "BRUNCH",
  CASHBACK = "CASHBACK",
  DOUBLETIME = "DOUBLETIME",
  COCKTAIL_SPECIAL = "COCKTAIL_SPECIAL",
  SINGLE_COPY = "SINGLE_COPY",
  DRINK_SPECIAL = "DRINK_SPECIAL",
  HAPPY_HOUR = "HAPPY_HOUR",
  CLEARANCE_SALE = "CLEARANCE_SALE",
  STOCK_SALE = "STOCK_SALE",
  NEW_PRODUCT = "NEW_PRODUCT",
  NEW_WARE = "NEW_WARE",
  NEW_ARRIVALS = "NEW_ARRIVALS",
  SAMPLE = "SAMPLE",
  SAMPLE_TRAINING = "SAMPLE_TRAINING",
  PRODUCT_DEMONSTRATION = "PRODUCT_DEMONSTRATION",
  SALES_ACTION = "SALES_ACTION",
  CLOSING_DOWN_SALE = "CLOSING_DOWN_SALE",
  RAUS_VERKAUF = "RAUS_VERKAUF",
  REMAINDER = "REMAINDER",
  SALE = "SALE",
  CLOSING_OUT_SALE = "CLOSING_OUT_SALE",
  BARGAIN_MARKET = "BARGAIN_MARKET",
  TASTER_ACTION = "TASTER_ACTION",
  TASTER_COURSE = "TASTER_COURSE",
  SPECIAL_ITEM = "SPECIAL_ITEM",
  SPECIAL_ACTION = "SPECIAL_ACTION",
  OTHER_ACTION = "OTHER_ACTION",
  SPECIAL = "SPECIAL",
  SSV = "SSV",
  TRAINING = "TRAINING",
  MEETING = "MEETING",
  WSV = "WSV",
  COMPETITION = "COMPETITION",
  MENU = "MENU",
  MODESCHAU = "MODESCHAU",
  SPEISEAKTION = "SPEISEAKTION",
  FOODSPECIAL = "FOODSPECIAL",
  ESSENSANGEBOT = "ESSENSANGEBOT",
  HOLIDAY_PROGRAM = "HOLIDAY_PROGRAM",
  SPORTS_BROADCAST = "SPORTS_BROADCAST",
  TV_BROADCAST = "TV_BROADCAST",
  PUBLIC_VIEWING = "PUBLIC_VIEWING",
  INFO_EVENT = "INFO_EVENT",
}

export enum DetailFilter {
  CLASSIC = "CLASSIC",
  JAZZ = "JAZZ",
  POP = "POP",
  ROCK = "ROCK",
  RAP = "RAP",
  ALTERNATIVE = "ALTERNATIVE",
  METAL = "METAL",
  EDM = "EDM",

  ART = "ART",
  TECHNIC = "TECHNIC",
  MODELLING = "MODELLING",
  HISTORY = "HISTORY",

  ACTION = "ACTION",
  HORROR = "HORROR",
  DRAMA = "DRAMA",
  COMEDY = "COMEDY",
  THRILLER = "THRILLER",
  CHILDREN = "CHILDREN",
  FANTASY = "FANTASY",
  DOCUMENTATION = "DOCUMENTATION",
  SCIFI = "SCIFI",

  SOCCER = "SOCCER",
  TENNIS = "TENNIS",
  VOLLEYBALL = "VOLLEYBALL",
  BASKETBALL = "BASKETBALL",
  SWIMMING = "SWIMMING",
  GYMNASTICS = "GYMNASTICS",
  FOOTBALL = "FOOTBALL",
  DANCING = "DANCING",
  CONTEST = "CONTEST",
  WATERPOLO = "WATERPOLO",
  BILLIARD = "BILLIARD",
  DART = "DART",
  TABLE_TENNIS = "TABLE_TENNIS",
  ATHLETICS = "ATHLETICS",
  FITNESS = "FITNESS",
  MARTIAL_ARTS = "MARTIAL_ARTS",
  COMPETITION = "COMPETITION",
  SQUASH = "SQUASH",
  NINEPINS = "NINEPINS",
  BATMINTON = "BATMINTON",
  RIDING = "RIDING",
  BIKEBALL = "BIKEBALL",
  HANDBALL = "HANDBALL",
  MOTORSPORT = "MOTORSPORT",
  YOGA = "YOGA",

  COOKING = "COOKING",
  BACKING = "BACKING",
  LANGUAGE = "LANGUAGE",
  ADVANCED_TRAINING = "ADVANCED_TRAINING",
  FURTHER_EDUCATION = "FURTHER_EDUCATION",
  LITERATURE = "LITERATURE",
  PAINTING = "PAINTING",
  DRAWING = "DRAWING",
  CRAFT = "CRAFT",

  SPORT_BENEFIT = "SPORT_BENEFIT",
  BENEFIT_EVENT = "BENEFIT_EVENT",
  BENEFIT_CONCERT = "BENEFIT_CONCERT",
  BENEFIT_FESTIVAL = "BENEFIT_FESTIVAL",
  BENEFIT_SALE = "BENEFIT_SALE",
  BENEFIT_ACTION = "BENEFIT_ACTION",

  CLUB_FESTIVAL = "CLUB_FESTIVAL",
  CITY_FESTIVAL = "CITY_FESTIVAL",
  VILLAGE_FESTIVAL = "VILLAGE_FESTIVAL",
  DISTRICT_FESTIVAL = "DISTRICT_FESTIVAL",

  CITY_TOUR = "CITY_TOUR",
  EXCURSION = "EXCURSION",
  HISTORICAL = "HISTORICAL",
  ARCHAEOLOGICAL = "ARCHAEOLOGICAL",
  THEMED_TOUR = "THEMED_TOUR",
  NATURE_TOUR = "NATURE_TOUR",

  MOVIE = "MOVIE",
  THEATRE = "THEATRE",
  MUSICAL = "MUSICAL",
  MUSIC = "MUSIC",
  VIDEO = "VIDEO",

  ANTIQUES = "ANTIQUES",
  BABYWARES = "BABYWARES",
  BUILDING_SUPPLIES = "BUILDING_SUPPLIES",
  BEAUTY = "BEAUTY",
  WEDDINGDRESS = "WEDDINGDRESS",
  GLASSES = "GLASSES",
  BOOKS = "BOOKS",
  OFFICE_SUPPLIES = "OFFICE_SUPPLIES",
  CAMPING_SUPPLIES = "CAMPING_SUPPLIES",
  DECORATIONS = "DECORATIONS",
  IRON_WARE = "IRON_WARE",
  ELECTRO = "ELECTRO",
  EROTIC = "EROTIC",
  PHOTOGRAPHY = "PHOTOGRAPHY",
  HAIR_STYLES = "HAIR_STYLES",
  BEVERAGES = "BEVERAGES",
  GLASS_WARE = "GLASS_WARE",
  HOUSEHOLD_TOOLS = "HOUSEHOLD_TOOLS",
  HOUSEHOLD_WARES = "HOUSEHOLD_WARES",
  HANDYMAN_SUPPLIES = "HANDYMAN_SUPPLIES",
  HEARING_ACOUSTICS = "HEARING_ACOUSTICS",
  JEWELER = "JEWELER",
  CHILDREN_SUPPLIES = "CHILDREN_SUPPLIES",
  NEAR_BODY_SERVICES = "NEAR_BODY_SERVICES",
  COSMETICS = "COSMETICS",
  DRY_GOODS = "DRY_GOODS",
  GROCERIES = "GROCERIES",
  LEATHER_WARES = "LEATHER_WARES",
  LAMPS = "LAMPS",
  MASSAGE = "MASSAGE",
  FURNITURE = "FURNITURE",
  FASHION = "FASHION",
  MUSICAL_INSTRUMENTS = "MUSICAL_INSTRUMENTS",
  OPTICS = "OPTICS",
  PARFUME = "PARFUME",
  TRAVELLING = "TRAVELLING",
  LOCKING_SYSTEMS = "LOCKING_SYSTEMS",
  LOCKSMITH = "LOCKSMITH",
  JEWELERY = "JEWELERY",
  STATIONERY = "STATIONERY",
  SHOES = "SHOES",
  TOYS = "TOYS",
  SPORTS_GOODS = "SPORTS_GOODS",
  TOBACCO = "TOBACCO",
  BAGS = "BAGS",
  TATTOO = "TATTOO",
  ANIMAL_SUPPLIES = "ANIMAL_SUPPLIES",
  PET_FOOD = "PET_FOOD",
  RECORDS = "RECORDS",
  CLOCKS = "CLOCKS",
  ENTERTAINMENT_SYSTEMS = "ENTERTAINMENT_SYSTEMS",
  WELLNESS = "WELLNESS",
  TOOLS = "TOOLS",
  WOOL = "WOOL",
  MAGAZINES = "MAGAZINES",
  NEWSPAPER = "NEWSPAPER",
  ZOO_ARTICLES = "ZOO_ARTICLES",
  PASTIRES = "PASTRIES",
  MEAT_PRODUCTS = "MEAT_PRODUCTS",
  SAUSAGE_PRODUCTS = "SAUSAGE_PRODUCTS",
  ACCESSORIES = "ACCESSORIES",
  CRAFT_SUPPLIES = "CRAFT_SUPPLIES",
  COMPUTER = "COMPUTER",
  MOVIES = "MOVIES",
  PC_GAMES = "PC_GAMES",
  CONSOLE_GAMES = "CONSOLE_GAMES",
  SOUVENIR = "SOUVENIR",
  HOME_AND_GARDEN = "HOME_AND_GARDEN",

  AUSSCHREIBUNG = "AUSSCHREIBUNG",
  CUP = "CUP",
  GEWINNSPIEL = "GEWINNSPIEL",
  LOTTERY = "LOTTERY",
  PREISAUSSCHREIBEN = "PREISAUSSCHREIBEN",
  TURNIER = "TURNIER",
  INFO_EVENT = "INFO_EVENT",
}

export enum TargetAudience {
  WITH_CHILDREN = "WITH_CHILDREN",
  WITH_ANIMALS = "WITH_ANIMALS",
  WITH_ELDERS = "WITH_ELDERS",
  WITH_DISABILITY = "WITH_DISABILITY",
}

/**
 * model used for fileData in stockImage and FileComponent
 */
export interface ImageData {
  fileName: string;
  fileType: FileType;
}

/**
 * Current state of individual event dates
 */
export enum DateState {
  READY = "READY",
  DELAYED = "DELAYED",
  CANCELLED = "CANCELLED",
  SOLD_OUT = "SOLD_OUT",
}
/**
 * Model to save dates and corresponding state of action
 */
export interface DateItem {
  date: Date;
  state: DateState;
  startTime: string;
  endTime: string;
  showDate: Date;
  statusDetail?: string;
}

/**
 * Method to create empty dateItem
 * @returns dateItem with default values
 */
export const createEmptyDateItem = (): DateItem => {
  return {
    date: new Date(),
    showDate: new Date(),
    state: DateState.READY,
    startTime: "09:00",
    endTime: "17:00",
  };
};

export interface EventHashTable {
  [key: string]: Event[];
}

export interface ActionHashTable {
  [key: string]: Action[];
}

export enum PopupType {
  EDIT = "EDIT",
  DELETE = "DELETE",
  DETAIL = "DETAIL",
  DUPLICATE = "DUPLICATE",
}
