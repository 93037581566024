import {
  AdminLayoutComponent,
  DropdownComponent,
  DropdownOption,
  generateNotification,
  NotificationTypes,
  PopupComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import JobEdit from "../../components/jobedit/JobEdit";
import "../../styles/ProviderConfiguration.style.scss";
import { useAxios } from "../../utils/AxiosUtil";
import { createEmptyJob, Job } from "../../utils/job/job.types";
import {
  createJobOnBackend,
  deleteJobById,
  fetchAllJobs,
  generateJobTableRows,
  updateJobOnBackend,
} from "../../utils/job/JobUtils";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import { createEmptyProvider, Provider } from "../../utils/user/User.types";
import { fetchAllProvider } from "../../utils/user/UserUtils";
import { ConfigContext, UserContext } from "../App";

interface ProviderAdvertisementConfigurationPageProps {}

const AdminAdvertisementPage: React.FC<
  ProviderAdvertisementConfigurationPageProps
> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const axios = useAxios();
  const { user } = useContext(UserContext);
  // eslint-disable-next-line
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const [currentJob, setCurrentJob] = useState<Job>(createEmptyJob(""));
  const [loadedJobs, setLoadedJobs] = useState<Job[]>([]);

  const [keyJobCreation, setKeyJobCreation] = useState<number>(0);
  const [jobToUpdate, setJobToUpdate] = useState<Job>();
  const [providerList, setProviderList] = useState<Provider[]>([]);

  /**
   * this useEffect loads all jobs and providers
   */
  useEffect(() => {
    if (!axios || !user) return;
    Promise.all([fetchAllJobs(axios), fetchAllProvider(axios)]).then(
      ([loadedJobs, providers]) => {
        setProviderList(providers);
        setKeyJobCreation(keyJobCreation + 1);
        setLoadedJobs(loadedJobs);
      }
    );
    // eslint-disable-next-line
  }, [axios, user]);

  /**
   * Helper to create job on the backend
   */
  const createJob = async (
    job: Job,
    jobImage: File | undefined
  ): Promise<void> => {
    createJobOnBackend(axios, job, jobImage).then((newJob) => {
      if (newJob) {
        setCurrentJob(createEmptyJob(localProvider.slugid!));
        loadedJobs.push(newJob);
        setLoadedJobs([...loadedJobs]);
        setKeyJobCreation(keyJobCreation + 1);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.creationSuccessful"),
          t("notification.content.success.jobCreation")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.jobCreation")
        );
    });
  };

  /**
   * Helper to update a local job on the backend and update the local state too
   * @param job data to update
   * @param jobImage optiona file data to append
   */
  const updateJob = async (
    job: Job,
    jobImage: File | undefined
  ): Promise<void> => {
    updateJobOnBackend(axios, job, jobImage).then((updatedJob) => {
      if (updatedJob) {
        setJobToUpdate(undefined);
        let foundIndex: number = loadedJobs.findIndex(
          (item) => item.id === updatedJob.id
        );
        if (foundIndex === -1) {
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.error"),
            t("notification.content.error.jobUpdate")
          );
          return;
        }
        loadedJobs[foundIndex] = updatedJob;
        setLoadedJobs([...loadedJobs]);
        setKeyJobCreation(keyJobCreation + 1);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.jobUpdate")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.jobUpdate")
        );
    });
  };

  /**
   * Helper to delete a job from the sevrer and the list
   * @param id of the job to delete
   */
  const deleteJobOnTheBackend = (id: string): void => {
    deleteJobById(axios, id).then((success) => {
      if (success) {
        setLoadedJobs([...loadedJobs.filter((item) => item.id! !== id)]);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.deleteSuccessful"),
          t("notification.content.success.deleteSuccessful")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.deletion")
        );
    });
  };

  /**
   * Helper to get dropdown options for providers with a optional filter
   *
   * @param filter optional filter function
   *
   * @returns a list of dropdown options
   */
  const createProviderDropdownEntries = (
    filter: (
      value: Provider,
      index: number,
      array: Provider[]
    ) => boolean = () => true
  ): DropdownOption[] => {
    return providerList.filter(filter).map((provider) => ({
      label: provider.name,
      value: provider.slugid,
    }));
  };

  return (
    <AdminLayoutComponent {...useNavLayout(CurrentPage.DASHBOARD_ADMIN_HOME)}>
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("providerAdvertisementConfigurationPage.title")}</h1>
      </div>

      <div
        id="provider-advertisement-configuration-wrapper"
        className="default-page-wrapper"
      >
        <RetractableComponent
          title={t("providerAdvertisementConfigurationPage.jobsCreation")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p>
            <b>{t("adminEventPage.providerSelection")}</b>
          </p>
          <DropdownComponent
            colors={{
              hover: {
                bgColor: appConfig?.highlightColor,
                fontColor: appConfig?.highlightColor,
              },
            }}
            onKeyUp={(key, activeIndex) => {
              if (key.key.toLocaleLowerCase() === "enter") {
                activeIndex && setLocalProvider(providerList[activeIndex]);
              }
            }}
            searchable
            dropdownOptions={createProviderDropdownEntries()}
            selectedOption={localProvider.slugid}
            onChange={(slugId) => {
              setLocalProvider(
                providerList.find((provider) => slugId === provider.slugid) ||
                  createEmptyProvider(false)
              );
            }}
          />
          {localProvider.id && (
            <JobEdit
              providerSlugId={localProvider.slugid!}
              providerId={localProvider.id!}
              jobToEdit={currentJob}
              key={keyJobCreation}
              onSave={(job, jobImage) => createJob(job, jobImage)}
            />
          )}
        </RetractableComponent>

        <RetractableComponent
          title={t("providerAdvertisementConfigurationPage.jobsListing")}
          type="border"
          icon={<ImagesIcon />}
        >
          <TableComponent
            header={t("providerAdvertisementConfigurationPage.tableHeader", {
              returnObjects: true,
            })}
            rows={generateJobTableRows(
              loadedJobs,
              deleteJobOnTheBackend,
              setJobToUpdate
            )}
            maxPageAmount={20}
          />

          <div className="provider-edit-popup">
            <PopupComponent
              open={!!jobToUpdate}
              toggleOpen={() => setJobToUpdate(undefined)}
            >
              <h2>{t("providerAdvertisementConfigurationPage.updateTitle")}</h2>
              <JobEdit
                providerSlugId={localProvider.slugid!}
                providerId={localProvider.id!}
                jobToEdit={jobToUpdate!}
                key={keyJobCreation}
                onSave={(job, jobImage) => updateJob(job, jobImage)}
              />
            </PopupComponent>
          </div>
        </RetractableComponent>

        {false && (
          <RetractableComponent
            title={"Social Media Beitrag erstellen"}
            type="border"
            icon={<ImagesIcon />}
          >
            TODO saved for future (said by Jochen)
          </RetractableComponent>
        )}
      </div>
    </AdminLayoutComponent>
  );
};

export default AdminAdvertisementPage;
