import { LatLngTuple } from "leaflet";

/**
 * possible types of files
 *
 * KEEP IN SYNC with storage-/config-/user-service
 */
export enum FileType {
  OTHER = "OTHER",
  MAIN_IMAGE = "MAIN_IMAGE",
  IMAGE = "IMAGE",
  DESCRIPTION_IMAGE = "DESCRIPTION_IMAGE",
  DOCUMENT = "DOCUMENT",
  SLIDESHOW_IMAGE = "SLIDESHOW_IMAGE",
  MENU = "MENU",
  PRICE = "PRICE",
  JOB = "JOB",
  ACTION = "ACTION",
  EVENT = "EVENT",
}

/**
 * enum of fixed good to know types.
 *
 * KEEP IN SYNC WITH CONFIG SERVICE
 */
export enum GoodToKnowType {
  /* EAT and DRINK && EXPERIENCE */
  RESERVATION = "RESERVATION",
  SMOKER = "SMOKER",
  NON_SMOKER = "NON_SMOKER",
  ANIMALS = "ANIMALS",
  /* EAT and DRINK */
  PICKUP = "PICKUP",
  MEETING_ROOMS = "MEETING_ROOMS",
  RENT = "RENT",
  OUTSIDE_SITTING = "OUTSIDE_SITTING",
  /* EAT and DRINK && SHOPPING */
  DELIVERY = "DELIVERY",
  /* SHOPPING */
  ORGANIC = "ORGANIC",
  /* ALL */
  BARRIER_FREE = "BARRIER_FREE",
  CUSTOMER_TOILET = "CUSTOMER_TOILET",
  CHANGING_TABLE = "CHANGING_TABLE",
  WIFI = "WIFI",
  SHOPPING_VOUCHER = "SHOPPING_VOUCHER",
  CREDIT_CARD = "CREDIT_CARD",
  GIROCARD = "GIROCARD",
  CUSTOMER_PARKING_SPOT = "CUSTOMER_PARKING_SPOT",
  CUSTOM = "CUSTOM",
}

// social media modell for a provider
export interface SocialMedia {
  facebook: string;
  linkedIn: string;
  youtube: string;
  instagram: string;
  whatsapp: string;
  [key: string]: string;
}

/**
 * Helper to generate an empty social media object
 * @returns empty social media object
 */
export const createEmptySocialMedia = (): SocialMedia => ({
  facebook: "",
  instagram: "",
  linkedIn: "",
  whatsapp: "",
  youtube: "",
});

// application config which is loaded from the server
export interface AppConfig {
  id?: string;
  mainColor: string;
  highlightColor: string;
  accentColor: string;
  fontColor: string;
  cityName: string;
  slideshow: SlideshowItem[];
  socialMedia: SocialMedia;
  platformName: string;
  slideshowIntervall: number;
  goodToKnow?: GoodToKnow[];
  map: MapConfig;
  aboutUs: AboutUs;
}

//interface to be displayed in the footer with information about the platform
export interface AboutUs {
  title: string;
  content: string;
}

// interface for mapConfiguration
export interface MapConfig {
  zoom: number;
  center: LatLngTuple;
}

/**
 * creates empty UserConfig to fill with data from server
 * @returns empty UserConfig
 */
export const createEmptyConfig = (): AppConfig => ({
  aboutUs: { title: "", content: "" },
  mainColor: "",
  highlightColor: "",
  accentColor: "",
  cityName: "",
  fontColor: "",
  slideshow: [],
  platformName: "",
  socialMedia: createEmptySocialMedia(),
  slideshowIntervall: 5,
  map: {
    zoom: 14,
    center: [0, 0],
  },
});

// item in a slidshow
export interface SlideshowItem {
  id?: string;
  position?: number;
  providerId?: string;
  active?: boolean;
  startDate?: Date;
  endDate?: Date;
  fileName?: string;
  mobileFileName?: string;
  header?: string;
  subText?: string;
  direction?: boolean;
  link?: string;
  name?: string;
  detailDescription?: string;
}

export const createEmptySlideshowItem = (): SlideshowItem => ({});

/**
 * model of a good to know item
 */
export interface GoodToKnow {
  id?: string;
  name?: string;
  type?: GoodToKnowType;
  fixed: boolean;
  active: boolean;
}
