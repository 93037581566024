import { FileType } from "../appConfig/config.types";
import { v4 as uuidv4 } from "uuid";
import { OpeningHours } from "../openinghours/OpeningHours.types";
import { createEmptyOpeningHours } from "../openinghours/OpeningHoursUtils";
import { Optional, Replace } from "../types/TypeUtils";

/**
 * enum for the days in the week
 * The Order is used to sort the days in some cases
 */
export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export enum Category {
  EAT_DRINK = "EAT_DRINK",
  EXPERIENCE = "EXPERIENCE",
  SHOPPING = "SHOPPING",
}

export enum TradeAndServiceCategories {
  TRADE = "TRADE",
  CRAFT = "CRAFT",
  SERVICE = "SERVICE",
  HEALTH = "HEALTH",
}

export enum MainType {
  // RESTAURANT
  BAR = "BAR",
  BACKERY = "BACKERY",
  CAFE = "CAFE",
  ICE = "ICE",
  SNACK = "SNACK",
  PUB = "PUB",
  BUTCHER_SHOP = "BUTCHER_SHOP",
  PIZZERIA = "PIZZERIA",
  RESTAURANT = "RESTAURANT",
  SPORTS_BAR = "SPORTS_BAR",
  BEER_GARDEN = "BEER_GARDEN",
  PASTRY_SHOP = "PASTRY_SHOP",
  IMBISS = "IMBISS",
  TAVERN = "TAVERN",
  CATERING = "CATERING",
  PARTY_SERVICE = "PARTY_SERVICE",
  PIZZA_SERVICE = "PIZZA_SERVICE",
  VINEYARD = "VINEYARD",

  // EXPERIENCE
  ART = "ART",
  THEATRE = "THEATRE",
  CONCERT = "CONCERT",
  OPER = "OPER",
  MUSICAL = "MUSICAL",
  COMEDY = "COMEDY",
  KABARETT = "KABARETT",
  VARIETE = "VARIETE",
  EXHIBITION = "EXHIBITION",
  CLUB = "CLUB",
  MUSEUM = "MUSEUM",
  GALERY = "GALERY",
  YOUTH_HOUSE = "YOUTH_HOUSE",
  YOUTH_CENTER = "YOUTH_CENTER",
  CINEMA = "CINEMA",
  DISCO = "DISCO",
  SWIMMING_POOL = "SWIMMING_POOL",
  SPORT = "SPORT",
  WELLNESS = "WELLNESS",
  TATTOO = "TATTOO",
  PIERCING = "PIERCING",
  SAUNA = "SAUNA",
  OUTDOOR_POOL = "OUTDOOR_POOL",
  PLAYGROUND = "PLAYGROUND",
  FOOTBALL_FIELD = "FOOTBALL_FIELD",
  FITNESS = "FITNESS",
  ASSOCIATION = "ASSOCIATION",
  MASSAGE = "MASSAGE",

  // SHOPPING
  ELECTRO = "ELECTRO",
  FASHION = "FASHION",
  FOOD = "FOOD",
  OFFICE = "OFFICE",
  STATIONERY = "STATIONERY",
  BOOK = "BOOK",
  DECORATIONS = "DECORATIONS",
  HOUSEHOLD_GOODS = "HOUSEHOLD_GOODS",
  BUILDING_SUPPLIES = "BUILDING_SUPPLIES",
  HOME_IMPROVEMENT = "HOME_IMPROVEMENT",
  TOBACCO = "TOBACCO",
  PHOTO_STORE = "PHOTO_STORE",
  HABERDASHERY = "HABERDASHERY",
  TOYS = "TOYS",
  FARM_STORE = "FARM_STORE",
  ORGANIC_MARKET = "ORGANIC_MARKET",
  CHILDREN_SUPPLIES = "CHILDREN_SUPPLIES",
  JEWELER = "JEWELER",
  HEARING_ACOUSTICS = "HEARING_ACOUSTICS",
  PURCHASE_SALE = "PURCHASE_SALE",
  TRAVEL_AGENCY = "TRAVEL_AGENCY",
  HAIRDRESSER = "HAIRDRESSER",
  COSMETICS = "COSMETICS",
  BEAUTY = "BEAUTY",
  GROCERIES = "GROCERIES",
  DIYMARKET = "DIYMARKET",
  OPTICIAN = "OPTICIAN",
  HEALTH = "HEALTH",
  SERVICE = "SERVICE",
  CRAFT = "CRAFT",
  // Deleted but used from customer
  ACTOR = "ACTOR",
  ROLL = "ROLL",
  DONER_KEBAP = "DONER_KEBAP",
  ACCESSOIRES = "ACCESSOIRES",
}

export enum DetailType {
  // Deleted but used from customer
  TABACCO = "TABACCO",
  DECORATIV = "DECORATIV",
  // RESTAURANT
  ALCOHOL_FREE = "ALCOHOL_FREE",
  ARABIAN = "ARABIAN",
  ASIAN = "ASIAN",
  BEER = "BEER",
  ORGANIC = "ORGANIC",
  ROAST = "ROAST",
  BREAD = "BREAD",
  BUNS = "BUNS",
  BUFFET = "BUFFET",
  BUNDESLIGA = "BUNDESLIGA",
  BURGER = "BURGER",
  CAPPUCCINO = "CAPPUCCINO",
  COCKTAIL = "COCKTAIL",
  DART = "DART",
  DELICACIES = "DELICACIES",
  GERMAN = "GERMAN",
  HOMEMADE = "HOMEMADE",
  DONER_KEBAB = "DONER_KEBAP",
  ICE = "ICE",
  ESPRESSO = "ESPRESSO",
  FISH = "FISH",
  FRAPPE = "FRAPPE",
  BREAKFAST = "BREAKFAST",
  SOCCER = "SOCCER",
  DRINKS = "DRINKS",
  GREEK = "GREEK",
  GRILL = "GRILL",
  GYROS = "GYROS",
  INTERNATIONAL = "INTERNATIONAL",
  ITALIAN = "ITALIAN",
  CAKE = "CAKE",
  LONGDRINK = "LONGDRINK",
  MENU = "MENU",
  PASTA = "PASTA",
  PIZZA = "PIZZA",
  PORTUGUESE = "PORTUGUESE",
  SALAD = "SALAD",
  SCHNAPPS = "SCHNAPPS",
  SCHNITZEL = "SCHNITZEL",
  SWABIAN = "SWABIAN",
  SKY = "SKY",
  SNACKS = "SNACKS",
  STEAK = "STEAK",
  SWEETS = "SWEETS",
  TURKISH = "TURKISH",
  VEGAN = "VEGAN",
  VEGETARIAN = "VEGETARIAN",
  WINE = "WINE",
  SAUSAGE = "SAUSAGE",
  LACTOSE_FREE = "LACTOSE_FREE",
  GLUTEN_FREE = "GLUTEN_FREE",
  SIRIAN = "SIRIAN",
  KICKER = "KICKER",
  BOARD_GAME = "BOARD_GAME",
  GAME_TABLE = "GAME_TABLE",

  // SHOPPING
  PURCHASE_SALE = "PURCHASE_SALE",
  ANTIQUES = "ANTIQUES",
  SUITS = "SUITS",
  BABYWARES = "BABYWARES",
  CRAFT_SUPPLIES = "CRAFT_SUPPLIES",
  BUILDING_SUPPLIES = "BUILDING_SUPPLIES",
  LIGHTING = "LIGHTING",
  BIOMARKET = "BIOMARKET",
  FLORIST = "FLORIST",
  WEDDING_DRESSES = "WEDDING_DRESSES",
  GLASSES = "GLASSES",
  BOOKS = "BOOKS",
  OFFICE_SUPPLIES = "OFFICE_SUPPLIES",
  CAMPING_SUPPLIES = "CAMPING_SUPPLIES",
  CDS = "CDS",
  DECORATIONS = "DECORATIONS",
  IRONWARE = "IRONWARE",
  EROTICSTORE = "EROTICSTORE",
  BANNER = "BANNER",
  FITNESSTOOLS = "FITNESSTOOLS",
  TILES = "TILES",
  FOTOSTORE = "FOTOSTORE",
  DRINKSTORE = "DRINKSTORE",
  GLASSWARE = "GLASSWARE",
  MARKETGARDEN = "MARKETGARDEN",
  HANDCRAFT_SUPPLIES = "HANDCRAFT_SUPPLIES",
  HANDYS = "HANDYS",
  HOUSEWARES = "HOUSEWARES",
  DIYSTORE = "DIYSTORE",
  DIY = "DIY",
  HEARTH = "HEARTH",
  WEDDING_EQUIPMENT = "WEDDING_EQUIPMENT",
  FARMSTORE = "FARMSTORE",
  HEARING_AID = "HEARING_AID",
  SHUTTERS = "SHUTTERS",
  JEWELER = "JEWELER",
  DEPARTMENT_STORE = "DEPARTMENT_STORE",
  CHILDREN_SUPPLIES = "CHILDREN_SUPPLIES",
  KIOSK = "KIOSK",
  GIFTS = "GIFTS",
  DRESSES = "DRESSES",
  CLOTHES = "CLOTHES",
  SUITCASES = "SUITCASES",
  DRYGOODS = "DRYGOODS",
  HYGIENE = "HYGIENE",
  GROCERIES = "GROCERIES",
  LEATHER_WARES = "LEATHER_WARES",
  LONGBOARD = "LONGBOARD",
  PAINTER_SUPPLIES = "PAINTER_SUPPLIES",
  FASHION = "FASHION",
  MUSIC = "MUSIC",
  MUSICAL_INSTRUMENTS = "MUSICAL_INSTRUMENTS",
  MUSICAL_SUPPLIES = "MUSICAL_SUPPLIES",
  FURNITURE = "FURNITURE",
  OPTICS = "OPTICS",
  PERFUMERY = "PERFUMERY",
  PORCELAIN_WARES = "PORCELAIN_WARES",
  CLEANING_SUPPLIES = "CLEANING_SUPPLIES",
  TRAVEL_AGENCY = "TRAVELAGENCY",
  RECORDS = "RECORDS",
  PLATES = "PLATES",
  LOCKING_SYSTEMS = "LOCKING_SYSTEMS",
  JEWELERY = "JEWELERY",
  STATIONERY = "STATIONERY",
  SHOES = "SHOES",
  SKATEBOARD = "SKATEBOARD",
  SKIS = "SKIS",
  SNOWBOARD = "SNOWBOARD",
  TOYS = "TOYS",
  TOBACCO = "TOBACCO",
  BAGS = "BAGS",
  TECHNICAL_SUPPLIES = "TECHNICAL_SUPPLIES",
  PETFOOD = "PETFOOD",
  WATCHES = "WATCHES",
  WATCH_ACCESSORIES = "WATCH_ACCESSORIES",
  CONSUMERMARKET = "CONSUMERMARKET",
  TOOLS = "TOOLS",
  NEWSPAPER = "NEWSPAPER",
  MAGAZINES = "MAGAZINES",
  PETSHOP = "PETSHOP",
  STOVES = "STOVES",
  COMBUSTIBLES = "COMBUSTIBLES",
  FUELS = "FUELS",
  HOUSEHOLD = "HOUSEHOLD",
  TV = "TV",
  COMPUTER = "COMPUTER",
  HIFI = "HIFI",
  SAUSAGES = "SAUSAGES",
  MEAT = "MEAT",
  BIO = "BIO",
  FAIRTRADE = "FAIRTRADE",
  MADE_IN_GERMANY = "MADE_IN_GERMANY",
  MADE_IN_EU = "MADE_IN_EU",
  ACCESSORIES = "ACCESSORIES",
  SOUVENIRS = "SOUVENIRS",
  GREETING_CARD = "GREETING_CARD",
  PAPETERIE = "PAPETERIE",
  WOOLEN_FABRIC = "WOOLEN_FABRIC",
  CONSTRUCTION_MACHINES = "CONSTRUCTION_MACHINES",
  CONSTRUCTION_EQUIPMENT = "CONSTRUCTION_EQUIPMENT",
  HARDWARESTORE = "HARDWARESTORE",
  WINDOWS = "WINDOWS",
  GARDENING_SUPPLIES = "GARDENING_SUPPLIES",

  //HANDICRAFT
  TILER = "TILER",
  ROOFER = "ROOFER",
  CARPENTER = "CARPENTER",
  CARPENTRY = "CARPENTRY",
  ELECTRICIAN = "ELECTRICIAN",
  PLUMBER = "PLUMBER",
  ROOF = "ROOF",
  ROOFING = "ROOFING",
  FACADE = "FACADE",
  SCAFFOLDING = "SCAFFOLDING",
  SCAFFOLDER = "SCAFFOLDER",
  STAGING = "STAGING",
  WINDOW_CONSTRUCTOR = "WINDOW_CONSTRUCTOR",
  GARDENING = "GARDENING",
  LANDSCAPING = "LANDSCAPING",
  INTERIORWORK = "INTERIORWORK",
  SURVEYOFFICE = "SURVEYOFFICE",

  //SERVICES
  IT_SERVICES = "IT_SERVICES",
  GRAPHIC_DESIGN = "GRAPHIC_DESIGN",
  ADMNISTRATION = "ADMINISTRATION",
  WORKSHOP = "WORKSHOP",
  INSTALLATIONS = "INSTALLATIONS",
  SANITARY = "SANITARY",
  ELECTRONICS = "ELECTRONICS",
  TIRE_SERVICE = "TIRE_SERVICE",
  CAR = "CAR",
  BIKE = "BIKE",
  BICYCLE = "BICYCLE",
  HAIR_DRESSER = "HAIR_DRESSER",
  COSMETICS = "COSMETICS",
  BEAUTY = "BEAUTY",
  TATTOO = "TATTOO",
  MASSAGE = "MASSAGE",
  HAIR_REMOVAL = "HAIR_REMOVAL",
  FUNERAL = "FUNERAL",
  BURIAL = "BURIAL",
  REGISTERED_AGENT = "REGISTERED_AGENT",
  CARETAKER_SERVICE = "CARETAKER_SERVICE",
  CLEANING_COMPANY = "CLEANING_COMPANY",
  PRINTSHOP = "PRINTSHOP",
  ENERGY_SUPPLY = "ENERGY_SUPPLY",
  CLEARING_OUT = "CLEARING_OUT",
  HOUSE_CLEARANCE = "HOUSE_CLEARANCE",
  DRIVING_SCHOOL = "DRIVING_SCHOOL",
  HAIRDRESSER_SHOP = "HAIRDRESSER_SHOP",
  BARBER = "BARBER",
  BARBERSHOP = "BARBERSHOP",
  INTERNET_AGENCY = "INTERNET_AGENCY",
  MARKETING_AGENCY = "MARKETING_AGENCY",
  ONLINE_SERVICES = "ONLINE_SERVICES",
  PAKETSHOP = "PAKETSHOP",
  PACKAGE_SERVICE = "PACKAGE_SERVICE",
  PIERCING = "PIERCING",
  PIERCER = "PIERCER",
  CLEANERS = "CLEANERS",
  PEST_CONTROL = "PEST_CONTROL",
  MOULD_REMOVAL = "MOULD_REMOVAL",
  LOCKSMITH = "LOCKSMITH",
  TAILORING = "TAILORING",
  SECURITY_SERVICE = "SECURITY_SERVICE",
  SOLARIUM = "SOLARIUM",
  TATTOOS = "TATTOOS",
  TRANSPORTATION = "TRANSPORTATION",
  ENTERTAINMENT_SERVICE = "ENTERTAINMENT_SERVICE",
  VIDEO_PRODCTION = "VIDEO_PRODUCTION",
  COMMERCIALS = "COMMERCIALS",
  ADVERTISING_AGENCY = "ADVERTISING_AGENCY",
  CONSTRUCTION_COMPANY = "CONSTRUCTION_COMPANY",
  ENERGY_CONSULTANT = "ENERGY_CONSULTANT",
  ARCHITECT = "ARCHITECT",
  AIRCONDITIONER = "AIRCONDITIONER",
  DELIVERY = "DELIVERY",
  MOUNTING_SERVICE = "MOUNTING_SERVICE",
  INSURANCE = "INSURANCE",
  VETERINARIAN = "VETERINARIAN",
  BANK = "BANK",
  PROPERTY = "PROPERTY",
  HEALTH_INSURANCE = "HEALTH_INSURANCE",
  COMMERCIAL_AGENCY = "COMMERCIAL_AGENCY",
  KEY_SERVICE = "KEY_SERVICE",
  ANIMAL_SHELTER = "ANIMAL_SHELTER",
  HELPLINE = "HELPLINE",
  POSTOFFICE = "POSTOFFICE",
  ESTATE_AGENT = "ESTATE_AGENT",

  //MEDICINE
  GENERAL_PRACTITIONER = "GENERAL_PRACTITIONER",
  FAMILY_DOCTOR = "FAMILY_DOCTOR",
  GYNECOLOGIST = "GYNECOLOGIST",
  ORTHOPEDIST = "ORTHOPEDIST",
  OTORHINOLARYNGOLOGIST = "OTORHINOLARYNGOLOGIST",
  DENTIST = "DENTIST",
  EMERGENCY_DOCTOR = "EMERGENCY_DOCTOR",
  ACUPUNCTURE = "ACUPUNCTURE",
  GERIATRIC_CARE = "GERIATRIC_CARE",
  PHARMACY = "PHARMACY",
  OPTICIAN = "OPTICIAN",
  CHIROPRACTOR = "CHIROPRACTOR",
  SURGERY = "SURGERY",
  OCCUPATIONAL_THERAPY = "OCCUPATIONAL_THERAPY",
  CHIROPODY = "CHIROPODY",
  GYNECOLOGY = "GYNECOLOGY",
  MIDWIFE = "MIDWIFE",
  HEARING_AIDS = "HEARINGAIDS",
  ALTERNATIVE_PRACTITIONER = "ALTERNATIVE_PRACTITIONER",
  THERAPEUTIC_PEDAGOGY = "THERAPEUTIC_PEDAGOGY",
  INTERNIST = "INTERNIST",
  PEDIATRIST = "PEDIATRIST",
  JUGENDARZT = "JUGENDARZT",
  NURSING = "NURSING",
  NURSING_SERVICE = "NURSING_SERVICE",
  LOGOPEDICS = "LOGOPEDICS",
  NATUROPATHY = "NATUROPATHY",
  PHYSIOTHERAPY = "PHYSIOTHERAPY",
  PSYCHOTHERAPY = "PSYCHOTHERAPY",
  REHAB = "REHAB",
  PAIN_THERAPY = "PAIN_THERAPY",
  SPORTSMEDICINE = "SPORTSMEDICINE",
  UROLOGY = "UROLOGY",
  MEDITATION = "MEDITATION",

  // EXPERIENCE
  ACTING = "ACTING",
  KIDS_THEATRE = "KIDS_THEATRE",
  PUPPET_THEATRE = "PUPPET_THEATRE",
  IMPRO_THEATRE = "IMPRO_THEATRE",
  JAZZ = "JAZZ",
  METAL = "METAL",
  RAP = "RAP",
  POP = "POP",
  ROCK = "ROCK",
  CLASSIC = "CLASSIC",
  ELECTRO = "ELECTRO",
  HIPHOP = "HIPHOP",
  ALTERNATIVE = "ALTERNATIVE",
  BASKETBALL = "BASKETBALL",
  BILLIARDS = "BILLIARDS",
  FOOTBALL = "FOOTBALL",
  SWIMMING = "SWIMMING",
  TENNIS = "TENNIS",
  TABLETENNIS = "TABLETENNIS",
  VOLLEYBALL = "VOLLEYBALL",
  BATMINTON = "BATMINTON",
  SQUASH = "SQUASH",
  NINEPINS = "NINEPINS",
  BOWLING = "BOWLING",
  RIDING = "RIDING",
  GYMNASTICS = "GYMNASTICS",
  YOGA = "YOGA",
  ZUMBA = "ZUMBA",
  BODEGA = "BODEGA",
  ATHLETICS = "ATHLETICS",
  WRESTLING = "WRESTLING",
  AEROBIC = "AEROBIC",
  JUDO = "JUDO",
}

export enum Allergens {
  GLUTEN = "GLUTEN",
  WHEAT = "WHEAT",
  RYE = "RYE",
  BARLEY = "BARLEY",
  OATS = "OATS",
  SPELT = "SPELT",
  KAMUT = "KAMUT",
  CRUSTACEAN = "CRUSTACEAN",
  EGGS = "EGGS",
  FISH = "FISH",
  PEANUT = "PEANUT",
  ALMONDS = "ALMONDS",
  HAZELNUT = "HAZELNUT",
  WALNUT = "WALNUT",
  CASHEW = "CASHEW",
  PECANUT = "PECANUT",
  PARANUT = "PARANUT",
  PISTACHIO = "PISTACHIO",
  MACADAMIA = "MACADAMIA",
  SOJA = "SOJA",
  MILK = "MILK",
  NUTS = "NUTS",
  CELERY = "CELERY",
  MUSTARD = "MUSTARD",
  SESAME = "SESAME",
  LUPIN = "LUPIN",
  MOLLUSK = "MOLLUSK",
  SULFURDIOXIDE = "SULFURDIOXIDE",
  PRESERVATIVE = "PRESERVATIVE",
  COLORANT = "COLORANT",
  ANTIOXIDANT = "ANTIOXIDANT",
  SACCHARIN = "SACCHARIN",
  CYCLAMAT = "CYCLAMAT",
  ASPARTAM = "ASPARTAM",
  ACESULFAM = "ACESULFAM",
  PHOSPHOR = "PHOSPHOR",
  SULPHURIZED = "SULPHURIZED",
  CHININ = "CHININ",
  COFFEIN = "COFFEIN",
  FLAVORENHANCER = "FLAVORENHANCER",
  BLACKENED = "BLACKENED",
  WAXED = "WAXED",
  GENETICALLYMODIFIED = "GENETICALLYMODIFIED",
}

/**
 * enum for tags of individual meals
 */
export enum FoodTags {
  GERMAN = "GERMAN",
  ALCOHOLFREE = "ALCOHOLFREE",
  ARABIC = "ARABIC",
  ASIAN = "ASIAN",
  BIO = "BIO",
  BUFFET = "BUFFET",
  FISH = "FISH",
  MEAT = "MEAT",
  WITHOUTGLUTEN = "WITHOUTGLUTEN",
  GREEK = "GREEK",
  SNACK = "SNACK",
  INDIAN = "INDIAN",
  ITALIAN = "ITALIAN",
  REGIONAL = "REGIONAL",
  TURKISH = "TURKISH",
  VEGAN = "VEGAN",
  VEGETARIAN = "VEGETARIAN",
}

/* Except NONE KEEP IN SYNC WITH USER SERVICE */
export enum UserRole {
  ADMIN = "ADMIN",
  PROVIDER = "PROVIDER",
  NONE = "NONE",
}

export interface IdName {
  id: string;
  name: string;
}

/**
 * enum for a provider profile state
 */
export enum StatusType {
  READY = "READY",
  LOCKED = "LOCKED",
  RELEASED = "RELEASED",
}

/**
 * model of a event location for easy selection
 */
export interface EventLocation {
  id: string;
  name: string;
  location: Location;
  phone?: string;
  mail?: string;
  website?: string;
}

/**
 * model of a location for vvk
 */
export interface VVKLocation {
  id: string;
  name: string;
  location: Location;
  phone?: string;
  mail?: string;
  website?: string;
  providerSlugId?: string;
}

/**
 * Helper to create an empty vvkLocation
 * @returns empty VVKLocation
 */
export const createEmptyVVKLocation = (): VVKLocation => ({
  id: uuidv4(),
  location: createEmptyLocation(),
  name: "",
});

/**
 * Helper to create empty event location
 * @returns empty event location
 */
export const createEmptyEventLocation = (): EventLocation => ({
  id: uuidv4(),
  location: createEmptyLocation(),
  name: "",
});

/**
 * model of a lunch for the provider
 */
export interface Lunch {
  id: string;
  startDate: Date;
  endDate: Date;
  menuItems: MenuItem[];
  days: DayOfWeek[];
  startTime: string;
  endTime: string;
  delivery: boolean;
  pickUp: boolean;
}

/**
 * Helper to create empty Lunch item
 * @returns newly created lunch item
 */
export const createEmptyLunch = (override?: Partial<Lunch>): Lunch => ({
  id: uuidv4(),
  startDate: new Date(),
  endDate: new Date(),
  menuItems: [],
  days: [],
  startTime: "",
  endTime: "",
  delivery: false,
  pickUp: false,
  ...override,
});

/**
 * model of the global config for the lunch
 */
export interface GlobalLunchConfig {
  weekCard?: boolean;
  days: DayOfWeek[];
  startTime: string;
  endTime: string;
}

/**
 * Helper to create an empty global lunch config
 * @returns empty model
 */
export const createEmptyGlobalLunchConfig = (): GlobalLunchConfig => ({
  days: [],
  endTime: "",
  startTime: "",
});

/**
 * Interface for user object which is the base for Provider and Admin
 */
export interface User {
  id?: string;
  createDate?: Date;
  password: string;
  mail: string;
  username: string;
  role: UserRole;
  disabled: boolean;
}

/**
 * Admin user
 */
export interface Admin extends User {
  firstname: string;
  lastname: string;
}

/**
 * Provider user
 */
export interface Provider extends User {
  slugid: string;
  name: string;
  category: Category;
  mainTypes: MainType[];
  detailTypes: DetailType[];
  owner: string;
  trafficCounter?: number;
  location: Location;
  phoneNumber: string;
  publicPhoneNumber: string;
  /** @deprecated */
  openingTimes: OpeningTime[];
  openingHours: OpeningHours;
  displayDescription: string;
  description: string;
  licenceId: string;
  socialMedia: SocialMediaProps;
  publicMail: string;
  files: FileItem[];
  menuItems: MenuItem[];
  priceItems: PriceItem[];
  customPriceItemsForActivity: PriceItem[];
  customTags: CustomTag[];
  webshopLink: string;
  holidayTimes: HolidayTime[];
  status: StatusType;
  globalLunchConfig: GlobalLunchConfig;
  lunchItems: Lunch[];
  eventLocationItems: EventLocation[];
  advanceBookingOffices: VVKLocation[];
  /** @deprecated */
  noOpeningTimes: boolean;
  /** @deprecated */
  noOpeningTimesText: string;
  firstname: string;
  lastname: string;
  publicWebsite: string;
  goodToKnowItems: string[];
  specialNotes: SpecialNote[];
}

/**
 * model of a timed specialNote
 */
export interface SpecialNote {
  id: string;
  text: string;
  title: string;
  startDate: Date;
  endDate: Date;
}

/**
 * Helper to gneerate empty SpecialNote Object
 * @returns empty SpecialNote
 */
export const createEmptySpecialNote = (): SpecialNote => ({
  id: uuidv4(),
  title: "",
  text: "",
  endDate: new Date(),
  startDate: new Date(),
});

/**
 * model of a price item
 */
export interface PriceItem {
  id: string;
  name: string;
  price: number;
  link: string;
  description: string;
}

/**
 * model for a custom tag for a provider
 */
export interface CustomTag {
  text: string;
  active?: boolean;
}

/**
 * request model for a custom tag creation
 */
export interface ProviderCustomTagCreationRequest {
  providerId: string;
  tag: string;
}

/**
 * Helper to create an empty custom tag
 * @returns empty custom tag
 */
export const createEmptyCustomTag = (): CustomTag => ({
  text: "",
});

export interface SocialMediaProps {
  facebook?: string;
  youtube?: string;
  linkedIn?: string;
  instagram?: string;
  whatsapp?: string;
  [key: number]: string;
}

/**
 * model of a fileitem which is stored in obejct store
 */
export interface FileItem {
  id: string;
  originalFilename: string;
  filename: string;
  type: FileType;
  mainimage: boolean;
}

/**
 * A new provider object for signup.
 * This type sets the property "standard" in "openingHours" to optional.
 */
export type EmptyProvider = Replace<
  Provider,
  "openingHours",
  Optional<OpeningHours, "standard">
>;

/**
 * Helper to create an empty provider object
 *
 * @param emptyOpeningHours boolean if the openinghours standard property should be undefined. Used in signup to show default values.
 *
 * @returns if emptyOpeningHours is false return normal provider object else return EmptyProvider object
 */
export const createEmptyProvider = <E extends boolean = false>(
  emptyOpeningHours: E = false as E
): E extends false ? Provider : EmptyProvider =>
  ({
    category: Category.EAT_DRINK,
    detailTypes: [],
    mainTypes: [],
    disabled: false,
    mail: "",
    name: "",
    role: UserRole.PROVIDER,
    slugid: "",
    username: "",
    password: "",
    owner: "",
    location: createEmptyLocation(),
    phoneNumber: "",
    openingTimes: [createEmptyOpeningTime(true)],
    openingHours: createEmptyOpeningHours(emptyOpeningHours),
    displayDescription: "",
    description: "",
    publicMail: "",
    socialMedia: {},
    files: [],
    menuItems: [],
    priceItems: [],
    customTags: [],
    webshopLink: "",
    holidayTimes: [],
    status: StatusType.RELEASED,
    globalLunchConfig: createEmptyGlobalLunchConfig(),
    lunchItems: [],
    eventLocationItems: [],
    noOpeningTimes: false,
    noOpeningTimesText: "",
    firstname: "",
    lastname: "",
    publicPhoneNumber: "",
    publicWebsite: "",
    goodToKnowItems: [],
  } as unknown as E extends false ? Provider : EmptyProvider);

/**
 * Helper to generate empty location object
 *
 * @returns empty location
 */
export const createEmptyLocation = (): Location => ({
  street: "",
  zipCode: "",
  city: "",
  lat: 0.0,
  lng: 0.0,
});

/**
 * location model
 */
export interface Location {
  street: string;
  zipCode: string;
  city: string;
  lat: number;
  lng: number;
}

/**
 * The opening times in a provider
 */
export interface OpeningTime {
  id: string;
  name?: string;
  defaultTime: boolean;
  startDate?: Date;
  endDate?: Date;
  hasBreakTimes: boolean;
  mon: OpeningTimeItem;
  tue: OpeningTimeItem;
  wed: OpeningTimeItem;
  thu: OpeningTimeItem;
  fri: OpeningTimeItem;
  sat: OpeningTimeItem;
  sun: OpeningTimeItem;
  [key: string]: OpeningTimeItem | boolean | Date | undefined | string;
}

/**
 * model fo a holiday item
 */
export interface HolidayTime {
  holidayDate: Date;
  name: string;
  startTime: string;
  endTime: string;
  open: boolean;
}

export const createEmptyHolidayTime = (
  name: string,
  date: Date
): HolidayTime => ({
  endTime: "00:00",
  holidayDate: date,
  name: name,
  startTime: "00:00",
  open: false,
});

/**
 * Helper to generate default opening time
 *
 * @param defaultTime defines if this is the default openingtimes
 * @returns OpeningTime
 */
export const createEmptyOpeningTime = (defaultTime: boolean): OpeningTime => ({
  id: uuidv4(),
  defaultTime: defaultTime,
  hasBreakTimes: false,
  mon: createEmptyOpeningTimeItem(DayOfWeek.MONDAY),
  tue: createEmptyOpeningTimeItem(DayOfWeek.TUESDAY),
  wed: createEmptyOpeningTimeItem(DayOfWeek.WEDNESDAY),
  thu: createEmptyOpeningTimeItem(DayOfWeek.THURSDAY),
  fri: createEmptyOpeningTimeItem(DayOfWeek.FRIDAY),
  sat: createEmptyOpeningTimeItem(DayOfWeek.SATURDAY, true),
  sun: createEmptyOpeningTimeItem(DayOfWeek.SUNDAY, true),
});

/**
 * Model of an item in the opening times
 */
export interface OpeningTimeItem {
  day: DayOfWeek;
  startTime: string;
  endTime: string;
  startBreakTime?: string;
  endBreakTime?: string;
  closed: boolean;
}

/**
 * Helper to generate default opening time item
 *
 * @param day current day of week enum
 * @param closed if this day is closed
 * @returns OpeningTimeItem
 */
export const createEmptyOpeningTimeItem = (
  day: DayOfWeek,
  closed: boolean = false
): OpeningTimeItem => ({
  day: day,
  endTime: "00:00",
  startTime: "00:00",
  closed,
});

/**
 * model of a request to delete a file item from a provider
 */
export interface ProviderFileRequest {
  providerId: string;
  filename?: string;
  type: FileType;
}

/**
 * Helper to generate an empty Provider image create request
 * @param providerId to load
 * @param type of uploaded file
 * @returns newly generated object
 */
export const createEmptyProviderFileCreationRequest = (
  providerId: string,
  type: FileType
): ProviderFileRequest => ({
  providerId: providerId,
  type: type,
});

/**
 * Helper to generate an empty Provider image delete request
 *
 * @param providerId to load
 * @param filename to remove from fileitem list
 * @param type of uploaded file
 * @returns newly generated object
 */
export const createEmptyProviderFileDeleteRequest = (
  providerId: string,
  filename: string,
  type: FileType
): ProviderFileRequest => ({
  filename: filename,
  providerId: providerId,
  type: type,
});

/**
 * model of a menu item in the provider
 */
export interface MenuItem {
  id: string;
  name: string;
  price: number;
  description: string;
  allergens: Allergens[];
  tags: FoodTags[];
  specialAllergen: string;
  days?: DayOfWeek[];
}

/**
 * Helper to generate an empty menu item for editing or creation
 * @returns empty menu item
 */
export const createEmptyMenuItem = (
  override?: Partial<MenuItem>
): MenuItem => ({
  id: uuidv4(),
  name: "",
  allergens: [],
  description: "",
  price: 0.0,
  specialAllergen: "",
  tags: [],
  ...override,
});

/**
 * Helper to generate an empty price item for editing or creation
 * @returns empty price item
 */
export const createEmptyPriceItem = (): PriceItem => ({
  id: uuidv4(),
  name: "",
  description: "",
  price: 0.0,
  link: "",
});

/**
 * model of a count response
 */
export interface CountResponse {
  action: number;
  event: number;
}

/**
 * Helper to create an empty count response
 * @returns empty count response
 */
export const createEmptyCountResponse = (): CountResponse => ({
  action: 0,
  event: 0,
});

/**
 * model of a needed temp config
 * type for signup openingtimes
 */
export interface TempSignupConfig {
  days: DayOfWeek[];
  sameDate: boolean;
  startTime: string;
  endTime: string;
  breakStartTime?: string;
  breakEndTime?: string;
}

/**
 * Helper to generate an empty TempSignupConfig
 * @returns empty TempSignupConfig
 */
export const createEmptyTempSignupConfig = (): TempSignupConfig => ({
  sameDate: false,
  endTime: "00:00",
  startTime: "00:00",
  days: [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
  ],
});
