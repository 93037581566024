import {
  AdminLayoutComponent,
  CheckboxComponent,
  DropdownComponent,
  DropdownOption,
  LoaderComponent,
  NotificationTypes,
  PopupComponent,
  TableComponent,
  generateNotification,
} from "deinestadtliebt-component-library";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as AddIcon } from "../assets/icons/add.svg";
import "../styles/LunchOverviewPageStyle.scss";
import { useAxios } from "../utils/AxiosUtil";
import { convertLunchIntoTableRows } from "../utils/lunch/LunchUtils";
import { CurrentPage } from "../utils/navigation/Navigation.types";
import { useNavLayout } from "../utils/navigation/NavigationUtils";
import { Lunch, Provider, UserRole } from "../utils/user/User.types";
import {
  fetchAllProvider,
  fetchUserById,
  getCorrectProviderFromListBySlugId,
  updateProviderLunchData,
} from "../utils/user/UserUtils";
import { ConfigContext, UserContext } from "./App";

const LunchOverviewPage: React.FC = () => {
  const { user } = useContext(UserContext);
  const { appConfig } = useContext(ConfigContext);

  const { t } = useTranslation();
  const axios = useAxios();
  const history = useHistory();

  const [isLoading, toggleLoading] = useState<boolean>(true);
  const [showOldLunch, toggleOldLunch] = useState<boolean>(false);
  const [loadedProvider, setLoadedProvider] = useState<Provider[]>([]);
  const [showProviderSelectPopup, setShowProviderSelectPopup] =
    useState<boolean>(false);
  const [localProvider, setLocalProvider] = useState<Provider>();
  const [lunchToDelete, setLunchToDelete] = useState<[Provider, Lunch]>();

  /**
   * Helper method to delete a lunch
   */
  const handleDeleteLunch = useCallback(
    (provider: Provider, lunch: Lunch) => {
      updateProviderLunchData(axios, {
        ...provider,
        lunchItems: provider.lunchItems.filter((item) => item.id !== lunch.id),
      }).then((success) => {
        if (success) {
          generateNotification(
            NotificationTypes.SUCCESS,
            t(`notification.title.success.creationSuccessful`),
            t(`notification.content.success.providerUpdate`)
          );
          setLoadedProvider(
            loadedProvider.map((prov) =>
              prov.id === provider.id
                ? {
                    ...provider,
                    lunchItems: provider.lunchItems.filter(
                      (item) => item.id !== lunch.id
                    ),
                  }
                : prov
            )
          );
        } else {
          generateNotification(
            NotificationTypes.ERROR,
            t(`notification.title.error.changesFailed`),
            t(`notification.content.error.providerUpdate`)
          );
        }
      });
      setLunchToDelete(undefined);
    },
    [axios, loadedProvider, t]
  );

  //useMemo hook to keep rows updated
  const rows = useMemo(() => {
    return convertLunchIntoTableRows(
      loadedProvider,
      toggleLoading,
      showOldLunch,
      user!,
      setLunchToDelete,
      (path: string, lunch: Lunch, provider: Provider) =>
        history.push(path, { lunch, provider })
    );
  }, [history, loadedProvider, showOldLunch, user]);

  /**
   * this useEffect loads neede data from the backend for
   * admin all users are loaded for a provider just itself
   */
  useEffect(() => {
    if (!axios || !user) return;

    switch (true) {
      case user.role === UserRole.ADMIN:
        fetchAllProvider(axios).then(setLoadedProvider);
        break;

      case user.role === UserRole.PROVIDER:
        fetchUserById(axios, user.id!, UserRole.PROVIDER).then((user) => {
          setLoadedProvider([user as Provider]);
        });
        break;
    }

    fetchAllProvider(axios);
  }, [axios, showOldLunch, user]);

  /**
   * Helper to get dropdown options for providers with a optional filter
   *
   * @param filter optional filter function
   *
   * @returns a list of dropdown options
   */
  const createProviderDropdownEntries = (
    filter: (
      value: Provider,
      index: number,
      array: Provider[]
    ) => boolean = () => true
  ): DropdownOption[] => {
    return loadedProvider.filter(filter).map((provider) => ({
      label: provider.name,
      value: provider.slugid,
    }));
  };

  return (
    <AdminLayoutComponent
      {...useNavLayout(
        user?.role === UserRole.PROVIDER
          ? CurrentPage.PROVIDER_LUNCHOVERVIEW
          : CurrentPage.ADMIN_LUNCHOVERVIEW
      )}
    >
      <PopupComponent
        title={t("lunchEdit.popupTitle")}
        open={showProviderSelectPopup}
        toggleOpen={setShowProviderSelectPopup}
        bottomButtons={[
          {
            value: t("lunchEdit.adminPopup"),
            onClick: () => {
              if (!localProvider) return;
              history.push("/admin/lunch/create", { provider: localProvider });
            },
          },
        ]}
      >
        <DropdownComponent
          colors={{
            hover: {
              bgColor: appConfig?.highlightColor,
              fontColor: appConfig?.highlightColor,
            },
          }}
          onKeyUp={(key, activeIndex) => {
            if (key.key.toLocaleLowerCase() === "enter") {
              activeIndex && setLocalProvider(loadedProvider[activeIndex]);
            }
          }}
          searchable
          dropdownOptions={createProviderDropdownEntries()}
          selectedOption={localProvider?.slugid || ""}
          onChange={(slugId) =>
            setLocalProvider(
              getCorrectProviderFromListBySlugId(loadedProvider, slugId)
            )
          }
        />
      </PopupComponent>
      <PopupComponent
        open={!!lunchToDelete}
        toggleOpen={() => setLunchToDelete(undefined)}
        bottomButtons={[
          {
            value: t("adminLunchOverviewPage.popup.abort"),
            onClick: () => setLunchToDelete(undefined),
          },
          {
            value: t("adminLunchOverviewPage.popup.delete"),
            onClick: () =>
              handleDeleteLunch(lunchToDelete![0], lunchToDelete![1]),
          },
        ]}
      >
        <p>{t("adminLunchOverviewPage.popup.text")}</p>
      </PopupComponent>
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("adminLunchOverviewPage.title")}</h1>
      </div>
      <div className="admin-activity-wrapper default-page-wrapper">
        <div className="admin-activity--add">
          <AddIcon
            onClick={() =>
              user?.role === UserRole.PROVIDER
                ? history.push("/config/lunch/create")
                : setShowProviderSelectPopup(true)
            }
          />
          <h3>{t("adminLunchOverviewPage.createNew")}</h3>
        </div>

        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <CheckboxComponent
              checked={showOldLunch}
              onCheck={() => toggleOldLunch(!showOldLunch)}
              value={t("adminLunchOverviewPage.showPastEvents")}
            />
            <TableComponent
              header={t("adminEventPage.lunch.tableHeader", {
                returnObjects: true,
              })}
              maxPageAmount={20}
              rows={rows}
            />
          </>
        )}
      </div>
    </AdminLayoutComponent>
  );
};

export default LunchOverviewPage;
